import {
    VERYFY_AGE
} from '../actions/types'

export default (state = false, action) => {
    switch (action.type) {
        case VERYFY_AGE:
            return true
        default:
            return state
    }
}