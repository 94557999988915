export const FETCH_BRANDS = 'FETCH_BRANDS'
export const FETCH_BRANDS_COUNTRIES = 'FETCH_BRANDS_COUNTRIES'
export const FETCH_BRAND_OVERVIEW = 'FETCH_BRAND_OVERVIEW'
export const FETCH_BRAND_FULL_RANGE = 'FETCH_BRAND_FULL_RANGE'
export const FETCH_BRAND_NEWS_AND_ACCOLADES = 'FETCH_BRAND_NEWS_AND_ACCOLADES'
export const FETCH_BRAND_SLIDE_PRESENTATIONS = 'FETCH_BRAND_SLIDE_PRESENTATIONS'
export const FETCH_BRAND_RANGES = 'FETCH_BRAND_RANGES'
export const FETCH_GALLERY_IMAGES = 'FETCH_GALLERY_IMAGES'
export const FETCH_PRODUCT = 'FETCH_PRODUCT'
export const FETCH_PRODUCT_RESOURCES = 'FETCH_PRODUCT_RESOURCES'
export const FETCH_NEWS = 'FETCH_NEWS'
export const FETCH_TEAM = 'FETCH_TEAM'
export const FETCH_PRODUCT_SLIDER_PRESENTATIONS = 'FETCH_PRODUCT_SLIDER_PRESENTATIONS'
export const VERYFY_AGE = 'VERYFY_AGE'
export const EMPLOYEE_SIGNIN = 'EMPLOYEE_SIGNIN'
export const EMPLOYEE_SIGNOUT = 'EMPLOYEE_SIGNOUT'
export const FETCH_EMPLOYEE_RESOURCES = 'FETCH_EMPLOYEE_RESOURCES'
export const SEND_CONTACTFORM_EMAIL = 'SEND_CONTACTFORM_EMAIL'
export const SEND_CONTACTFORM_EMAIL_ERROR = 'SEND_CONTACTFORM_EMAIL_ERROR'