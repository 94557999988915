import {
    FETCH_BRANDS_COUNTRIES
} from '../actions/types'

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_BRANDS_COUNTRIES:
            return action.payload
        default:
            return state
    }
}