import {
    SEND_CONTACTFORM_EMAIL,
    SEND_CONTACTFORM_EMAIL_ERROR
} from '../actions/types'

export default (state = {}, action) => {
    switch(action.type){
        case SEND_CONTACTFORM_EMAIL:
            return {
                sent: true,
                success: action.payload
            }
        case SEND_CONTACTFORM_EMAIL_ERROR:
            return {
                sent: false,
                error: action.payload
            }
        default:
            return state
    }
}