import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import needHelp from '../../assets/img/get-in-touch.jpg'
import {ButtonLink} from '../buttons'

class NeedHelpSection extends React.Component {

    render(){
        return(
            <section className="in_need-help-section" style={{
                backgroundImage:`url(${needHelp})`
            }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={12} lg={8}>
                            <h2 className="in_heading-1 text-center">Get In Touch</h2>
                            <p className="in_paragraph-2 text-center">We are here to help answer any questions you might have. We look forward to hearing from you.</p>
                            <ButtonLink text="Contact Us" link="/contact" />
                        </Col>
                    </Row>
                </Container>
            </section>
            
        )
    }
}

export default NeedHelpSection