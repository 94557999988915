import React from 'react'

class BackgroundImageItem extends React.Component {
    render(){

        const { bannerWidescreen, bannerMobile, image, altBanner, altImage } = this.props.data

        return(
            <div className="in_background-image-item" 
                title={altBanner ? altBanner : 'Indigo Wine Group'} 
                aria-label={altBanner ? altBanner : 'Indigo Wine Group'}
                style={{
                    backgroundImage: `url(${window.innerWidth > 400 ? bannerWidescreen : bannerMobile})`
                }}
            >
                {
                    image && (
                        <figure className="in_inner">
                            <img src={image} alt={altImage} />
                        </figure>
                    )
                }
            </div>
        )
    }
}

export default BackgroundImageItem