import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Button} from '../buttons'
import {connect} from 'react-redux'
import {
    employeeSignout
} from '../../actions'

class EmployeeAuth extends React.Component {

    onSignout = () => {
        this.props.employeeSignout(this.props.token)
    }

    render(){
        return(
            <Container>
                <Row>
                    <Col className="text-center mt-5">
                        <Button 
                            onClick={this.onSignout} 
                            text="Logout"
                        />
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default connect(null, {
    employeeSignout
})(EmployeeAuth)