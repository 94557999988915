import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import indigoLogo from '../assets/img/indigo-logo-footer.svg'
import facebookIcon from '../assets/img/iconmonstr-facebook-white.png'
import instagramIcon from '../assets/img/iconmonstr-instagram-9-64.png'
import {NavLinksWidescreen, CopyWrightLinks} from './NavLinks'
import {
    ButtonLink
} from './buttons'

class Footer extends React.Component {
    render(){
        return(
            <footer className="in_footer">
                <Container>
                    <Row>
                        <Col sm={12} lg={5}>
                            <img className="in_logo-footer" src={indigoLogo} alt="Indigo Wine Group" title="Indigo Wine Group" />
                            <p className="in_paragraph">
                                Since inception in 2002, the Indigo Wine Group, a national wine importing company, has dedicated itself to developing and maintaining strong partnerships with suppliers and customers alike, by seeking out superior wines of exceptional value capable of driving growth.
                            </p>
                        </Col>
                        <Col sm={12} lg={4}>
                            <h4 className="in_heading-3">Contact Us</h4>
                            <ul className="in_ul-contact">
                                <li>
                                    <h5 className="in_heading-8">TELEPHONE</h5>
                                    <a href="tel:(941) 483-1816" className="in_paragraph" title="Call us">(941) 483-1816</a>
                                </li>
                                <li>
                                    <h5 className="in_heading-8">ADDRESS</h5>
                                    <a href="https://goo.gl/maps/BCdKzHZzMWNC9Dgv5" target="_blank" className="in_paragraph" title="Open Google Maps" rel="noopener noreferrer">1501 S. Tamiami Tr. #501 Venice, Florida 34285, USA</a>
                                </li>
                                <li>
                                    <h5 className="in_heading-8">E-MAIL</h5>
                                    <a href="mailto:contact@indigowinegroup.com?subject=Indigo Wine Group Enquiry" className="in_paragraph" title="Send us an e-mail">contact@indigowinegroup.com</a>
                                </li>
                            </ul>
                        </Col>
                        <Col sm={12} lg={3}>
                            <h4 className="in_heading-3 in_menu-text">Menu</h4>
                            <ul className="in_ul-menu">
                                <NavLinksWidescreen />
                            </ul>
                            <ButtonLink 
                                text="Employee Forms"
                                link="/employee"
                                title="Go to Employee resources platform"
                            />
                        </Col>
                    </Row>
                </Container>
                <section className="in_bottom-footer">
                    <Container>
                        <div className="in_inner">
                            <CopyWrightLinks />
                            <div>
                                <a href="https://www.facebook.com/Indigo-Wine-Group-112033393909227" target="_blank" className="in_facebook" rel="noopener noreferrer">
                                    <img src={facebookIcon} alt="Facebook" />
                                </a>
                                <a href="https://www.instagram.com/indigowinegroup/" target="_blank" className="in_instagram pl-2" rel="noopener noreferrer">
                                    <img src={instagramIcon} alt="Instagram" width="30" height="30" />
                                </a>
                            </div>
                            
                        </div>
                    </Container>
                </section>
            </footer>
        )
    }
}

export default Footer