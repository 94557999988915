import React from 'react'
import {Link} from 'react-router-dom'
import AnimateScale from '../AnimateScale'

class BrandItem extends React.Component {
    
    render(){
        const {image, link, text, alt} = this.props
        return(            
            <Link to={link} className="in_brand-item text-center" title={`View ${alt} Wine Portfolio`}>
                <AnimateScale>
                    <div className="in_inner in_overflow-hidden">
                        <figure>
                            <img src={image} alt={alt} />
                        </figure> 
                    </div>
                    <h3 className="in_paragraph-6">{text}</h3>     
                </AnimateScale>          
            </Link>            
        )
    }
}

export default BrandItem