import React from 'react'
import Nav from '../components/Nav'
import NeedHelpSection from '../components/sections/NeedHelpSection'
import BrandTopHeader from '../components/BrandTopHeader'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchBrandOverview
} from '../actions'

//tabs
import BrandOverviewTab from '../components/tabs/BrandOverviewTab'
import BrandRangeTab from '../components/tabs/BrandRangeTab'
import BrandImageGalleryTab from '../components/tabs/BrandImageGalleryTab'
import BrandNewsAndAccoladesTab from '../components/tabs/BrandNewsAndAccoladesTab'
import BrandProductTab from '../components/tabs/BrandProductTab'

class BrandPortfolio extends React.Component{

    componentDidMount(){    
        this.props.fetchBrandOverview(this.props.match.params.brandSearchName)          
    }

    componentDidUpdate(prevProps){    
        if(this.props.match.params.brandSearchName !== prevProps.match.params.brandSearchName){
            this.props.fetchBrandOverview(this.props.match.params.brandSearchName)
        }           
    }

    renderTabs(){

        const brand = this.props.match.params.brandSearchName ? this.props.match.params.brandSearchName : null
        const product = this.props.match.params.productSearchName ? this.props.match.params.productSearchName : null

        if(product){
            return(
                <BrandProductTab 
                    brand={brand} 
                    product={product} 
                    overview={this.props.overview}
                />
            )
        }

        switch(this.props.match.params.searchName) {
            case 'overview':
                return <BrandOverviewTab data={this.props.overview} brand={brand} />
            case 'range':
                return <BrandRangeTab data={this.props.overview} brand={brand} />
            case 'image-gallery':
                return <BrandImageGalleryTab data={this.props.overview} brand={brand} />
            case 'news-and-accolades':
                return <BrandNewsAndAccoladesTab data={this.props.overview} brand={brand} />
            default:
                return <BrandOverviewTab data={this.props.overview} brand={brand} />
        }             
    }

    renderPageContent(){
        return(
            <Nav>
                <section className="in_brand-portfolio-section">

                    <Container>
                        <BrandTopHeader className="in_mobile-tablet" 
                            data={this.props.overview}
                        />
                        <Row>
                            <Col sm={12} md={3} lg={2}>
                                <div className="in_tabs">
                                    <NavLink to={`/portfolio/${this.props.match.params.brandSearchName}/overview`} className={`in_tabs-button ${!this.props.match.params.searchName ? 'in_active' : ''}`} activeClassName="in_active">Overview</NavLink>
                                    <NavLink to={`/portfolio/${this.props.match.params.brandSearchName}/range`} className="in_tabs-button" activeClassName="in_active">Range</NavLink>
                                    <NavLink to={`/portfolio/${this.props.match.params.brandSearchName}/image-gallery`} className="in_tabs-button" activeClassName="in_active">Image Gallery</NavLink>
                                    <NavLink to={`/portfolio/${this.props.match.params.brandSearchName}/news-and-accolades`} className="in_tabs-button" activeClassName="in_active">News & Accolades</NavLink>
                                </div>
                                
                            </Col>
                            <Col sm={12} md={9} lg={10}>
                                <BrandTopHeader className="in_tablet" 
                                    data={this.props.overview} 
                                />
                                {this.renderTabs()}
                            </Col>
                        </Row>
                    </Container>

                </section>

                <NeedHelpSection />
            </Nav>
        )
    }

    render(){
        return this.renderPageContent()
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        overview: state.brandOverviewState[ownProps.match.params.brandSearchName]
    }
}

export default connect(mapStateToProps, {
    fetchBrandOverview
})(BrandPortfolio)