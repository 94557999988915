import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import EmployeeAuth from '../components/Employee/EmployeeAuth'
import EmployeeLogout from '../components/Employee/EmployeeLogout'
import EmployeeLanding from '../components/Employee/EmployeeLanding'
import EmployeeResources from '../components/Employee/EmployeeResources'
import {connect} from 'react-redux'

class Employee extends React.Component{

    state = {
        loggedIn: false
    }

    camelize = (str) => {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    renderPageContent(){
        
        if(!this.props.auth.token){
            return(
                <EmployeeAuth />
            )
        }

        const searchName = this.props.match.params.searchName

        switch(searchName){
            case 'depletion-reports':
                return <EmployeeResources 
                    searchName={searchName} 
                    headline="Depletion Reports"
                    token={this.props.auth.token} />
            case 'marketing-spread-sheet':
                return <EmployeeResources 
                    searchName={searchName} 
                    headline="Marketing Spreadsheet"
                    token={this.props.auth.token} />
            case 'holiday-list':
                return <EmployeeResources 
                    searchName={searchName} 
                    headline="Holiday List"
                    token={this.props.auth.token} />
            case 'vacation-request':
                return <EmployeeResources 
                    searchName={searchName} 
                    headline="Vacation Request"
                    token={this.props.auth.token} />
            case 'expense-report':
                return <EmployeeResources
                    searchName={searchName} 
                    headline="Expense Report"
                    token={this.props.auth.token} />
            case 'mileage-report':
                return <EmployeeResources
                    searchName={searchName} 
                    headline="Mileage Report"
                    token={this.props.auth.token} />
            default:
                return <EmployeeLanding />
        }
    }

    render(){

        return(
            <React.Fragment>
                <Helmet>
                    <title>Indigo Wine Group | Employee</title>
                    <meta name="description" content="Sign in to enter Employee Resources. This content is restricted to Indigo employees. International wines imported and distributed throughout the USA by Indigo Wine Group." />
                </Helmet>
                <Nav>
                    <section className="in_employement-section">
                        {this.renderPageContent()}  
                        {
                            this.props.auth.token && (
                                <EmployeeLogout token={this.props.auth.token} /> 
                            )
                        }
                    </section>
                </Nav>                
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return{
        auth: state.employeeSigninState
    }
}

export default connect(mapStateToProps)(Employee)