import React from 'react';
import { Helmet } from 'react-helmet';
// https://codesandbox.io/s/great-sunset-qrm0l?file=/src/FlocklerEmbed.js:862-989

export default class FlocklerEmbed extends React.Component {
  constructor(props) {
    super(props);
    this.state = { embed: null };
  }

  componentDidMount() {
    const uuid = this.props.uuidFlockler;
    const interval = window.setInterval(() => {
      if (
        window.flcklr &&
        window.flcklr.Embeds &&
        window.flcklr.EmbedConfigs[uuid]
      ) {
        window.clearInterval(interval);

        const embed = window.flcklr.Embeds.create(
          window.flcklr.EmbedConfigs[uuid]
        );

        this.setState({
          embed: embed
        });
      }
    }, 16);
  }

  componentWillUnmount() {
    if (this.state.embed) {
      this.state.embed.destroy();
      this.setState({ embed: null });
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <script
            src={`https://plugins.flockler.com/embed/${this.props.embedCodeId}?autoload=false`}
          />
        </Helmet>
        <div id={`flockler-embed-${this.props.uuidFlockler}`} />
      </>
    );
  }
}
