import React from 'react'
import Helmet from 'react-helmet'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {HR} from '../ui'
import YoutubeItem from '../items/YoutubeItem'
import {connect} from 'react-redux'
import {
    fetchProduct,
    fetchBrandFullRange,
    fetchProductSlidePresentations
} from '../../actions'
import Loader from '../Loader'
import {BASE_URL} from '../../path'
import {getLastSegmentOfURL} from '../../functions'
import ProductResourcesTabs from './ProductResourcesTabs'
import ProductCarousel from '../ProductCarousel'
import ProductCarouselItem from '../items/ProductCarouselItem'
import ProductItem from '../items/ProductItem'
import NewsItem from '../items/NewsItem'

class BrandProductTab extends React.Component {

    componentDidMount(){
        this.props.fetchProduct(this.props.product)
        this.props.fetchBrandFullRange(this.props.brand)
        this.props.fetchProductSlidePresentations(this.props.product)
    }

    componentDidUpdate(prevProps){
        if(prevProps.product !== this.props.product){
            this.props.fetchProduct(this.props.product)
            this.props.fetchBrandFullRange(this.props.brand)
            this.props.fetchProductSlidePresentations(this.props.product)
        }        
    }

    renderCarouselItems(){
        return this.props.fullRange.map((item, index) => {
            return(
                <ProductCarouselItem 
                    key={index}
                    text={item.title}
                    image={`${BASE_URL}${item.image}`}
                    link={`/portfolio/${this.props.brand}/range/${item.search_name}`}
                    alt={item.title}
                />
            )
        })
    }

    renderItems(){
        return this.props.fullRange.map((item, index) => {
            if(item.title !== this.props.data.title){
                return(
                    <Col sm={12} md={6} lg={4} className="mb-5" key={index}>
                        <ProductItem 
                            text={item.title}
                            image={`${BASE_URL}${item.image}`}
                            link={`/portfolio/${this.props.brand}/range/${item.search_name}`}
                            alt={item.title}
                        />
                    </Col>
                )
            }       
            return <React.Fragment key={index}></React.Fragment>     
        })
    }

    renderFullRange(){
        if(this.props.fullRange.length > 3){
            return(
                <>
                    <h2 className="in_heading-3 mt-3">Range</h2>
                    <ProductCarousel>
                        {this.renderCarouselItems()}
                    </ProductCarousel>
                </>
            )
        }
        return(
            <section className="in_full-range-section">
                <h2 className="in_heading-3 mt-3">Range</h2>
                <Row>
                    {this.renderItems()}
                </Row>
            </section>
        )
    }

    renderSliderPresentationItems(){
        return this.props.slidePresentations.map((item, index) => {
            return(
                <Col sm={12} md={6} lg={4} key={index}>
                    <NewsItem 
                        image={`${BASE_URL}${item.image}`}
                        text={item.title}
                        link={`${BASE_URL}${item.pdf}`}
                        alt={item.title}
                    />
                </Col>
            )
        })
    }
    
    render(){
        if(!this.props.data || !this.props.overview) { return <Loader /> }

        const {
            title,
            description,
            key_features,
            image,
            video,
            region,
            winemaker,
            serving_temp,
            allergens,
            alcohol,
            total_acidity,
            residual_sugar,
            oak_maturation,
            pH
        } = this.props.data

        return(
            <React.Fragment>
                <Helmet>
                    <title>Indigo Wine Group | {title}</title>
                    <meta name="description" content={description ? description : 'We are dedicated to developing and maintaining strong partnerships with superior wine brands of exceptional value.'} />
                </Helmet>
                <section className="in_product-section">
                    <Row>
                        <Col xs={12} sm={6} lg={7}>
                            <h1 className="in_heading-3">{title}</h1>
                            {
                                key_features && <div className="in_paragraph" dangerouslySetInnerHTML={{ __html: key_features }}></div>
                            }
                            
                            <HR />
                            <h3 className="in_heading-5">Tasting Notes</h3>
                            
                            <table>
                                <tbody>
                                    {
                                        region && (
                                            <tr>
                                                <td className="in_paragraph-6" valign="top">Region:</td>
                                                <td className="in_paragraph" valign="top">{region}</td>
                                            </tr>
                                        )
                                    }
                                    {
                                        winemaker && (
                                            <tr>
                                                <td className="in_paragraph-6" valign="top">Winemaker:</td>
                                                <td className="in_paragraph" valign="top">{winemaker}</td>
                                            </tr>
                                        )
                                    }
                                    {
                                        serving_temp && (
                                            <tr>
                                                <td className="in_paragraph-6" valign="top">Serving temp:</td>
                                                <td className="in_paragraph" valign="top">{serving_temp}</td>
                                            </tr>
                                        )
                                    }
                                    {
                                        allergens && (
                                            <tr>
                                                <td className="in_paragraph-6" valign="top">Allergens:</td>
                                                <td className="in_paragraph" valign="top">{allergens}</td>
                                            </tr>  
                                        )
                                    }
                                    {
                                        alcohol && (
                                            <tr>
                                                <td className="in_paragraph-6" valign="top">Alcohol:</td>
                                                <td className="in_paragraph" valign="top">{alcohol}</td>
                                            </tr>
                                        )
                                    }
                                    {
                                        pH && (
                                            <tr>
                                                <td className="in_paragraph-6" valign="top">P/H:</td>
                                                <td className="in_paragraph" valign="top">{pH}</td>
                                            </tr>
                                        )
                                    }
                                    {
                                        total_acidity && (
                                            <tr>
                                                <td className="in_paragraph-6" valign="top">Total Acidity:</td>
                                                <td className="in_paragraph" valign="top">{total_acidity}</td>
                                            </tr>
                                        )
                                    }
                                    {
                                        residual_sugar && (
                                            <tr>
                                                <td className="in_paragraph-6" valign="top">Residual Sugar:</td>
                                                <td className="in_paragraph" valign="top">{residual_sugar}</td>
                                            </tr>
                                        )
                                    }
                                    {
                                        oak_maturation && (
                                            <tr>
                                                <td className="in_paragraph-6" valign="top">Oak Maturation:</td>
                                                <td className="in_paragraph" valign="top">{oak_maturation}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </Col>
                        {
                            image && (
                                <Col xs={12} sm={6} lg={5}>
                                    <figure className="mt-3 pt-3">
                                        <img src={`${BASE_URL}${image}`} alt={title} />
                                    </figure>
                                </Col>
                            )
                        }    
                        <Col sm={12} className="mt-5">
                             <ProductResourcesTabs product={this.props.product} />
                        </Col>                   
                        <Col sm={12} className="mt-5">
                            {
                                video && (
                                    <YoutubeItem 
                                        title={title}
                                        link={getLastSegmentOfURL(video)}
                                    />
                                )
                            }

                            {
                                this.props.slidePresentations && this.props.slidePresentations.length > 0 && ( 
                                    <>
                                        <section className="in_slidepresentation-list-section">
                                            <h2 className="in_heading-3">Slide Presentation</h2>
                                            <Row>
                                                {this.renderSliderPresentationItems()}
                                            </Row>
                                        </section> 
                                        <HR />
                                    </>
                                )
                            }

                            { this.props.fullRange && this.props.fullRange.length > 1 && this.renderFullRange() }

                        </Col>
                    </Row>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.productState[ownProps.product],
        fullRange: state.brandFullRangeState[ownProps.brand],
        slidePresentations: state.productSlidePresentationsState[ownProps.product]
    }
}

export default connect(mapStateToProps, {
    fetchProduct,
    fetchBrandFullRange,
    fetchProductSlidePresentations
})(BrandProductTab)