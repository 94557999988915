import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BrandItem from '../items/BrandItem'
import {connect} from 'react-redux'
import {
    fetchBrands
} from '../../actions'
import {BASE_URL} from '../../path'

class WinePortfolioSection extends React.Component{

    componentDidMount(){
        this.props.fetchBrands()
    }

    renderList(){
        return this.props.brands.map((item, index) => {
            return(
                <Col xs={6} sm={4} md={4} lg={2} className="text-center" key={index}>
                    <BrandItem 
                        text={item.title}
                        image={`${BASE_URL}${item.logo}`}
                        link={`/portfolio/${item.search_name}`}
                        alt={item.title}
                    />
                </Col>
            )
        })
    }

    render(){
        return(
            <section className="in_wine-portfolio-section">
                <Container>
                    <Row>
                        <Col sm={12} className="text-center">
                            <h2><span className="in_heading-4">OUR</span><br /> <span className="in_heading-1">Wine Portfolio</span></h2>
                            <p className="in_paragraph">We are dedicated to developing and maintaining strong partnerships with superior wine brands of exceptional value.</p>
                        </Col>
                    </Row>
                </Container>
                <Container className="in_wp-container">
                    <Row>
                        { this.renderList() }
                    </Row>
                </Container>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        brands: state.brandsState
    }
}

export default connect(mapStateToProps, {
    fetchBrands
})(WinePortfolioSection)