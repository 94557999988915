import React from 'react'
import DoubleColumnItem from '../items/DoubleColumnItem'

//temp images
import soutfAfrica from '../../assets/img/South-Africa-flag.jpg'
import newZealand from '../../assets/img/New-Zealand-flag.jpg'
import australia from '../../assets/img/Australia-flag.jpg'
import argentina from '../../assets/img/Argentinia-flag.jpg'

class WineRegionsSection extends React.Component{
    render(){
        return(
            <React.Fragment>
                <DoubleColumnItem 
                    order
                    id="south-africa"
                    image={soutfAfrica}
                    title="Wine Region"
                    subtitle="South Africa"
                    description="South African wine has a history dating back to 1659, with the first bottle produced in Cape Town by its founder Jan van Riebeeck. Access to international markets led to new investment in the South African wine market. Production is concentrated around Cape Town, with major vineyard and production centers at Constantia, Paarl, Stellenbosch and Worcester. There are about 60 appellations within the Wine of Origin (WO) system, which was implemented in 1973 with a hierarchy of designated production regions, districts and wards."
                    link="https://www.wosa.co.za/"
                    linkTitle="Go To https://www.wosa.co.za/"
                />
                <DoubleColumnItem 
                    image={argentina}
                    id="argentina"
                    title="Wine Region"
                    subtitle="Argentina"
                    description="Argentina is the fifth largest producer of wine in the world. Argentine wine, as with some aspects of Argentine cuisine, has its roots in Spain. During the Spanish colonization of the Americas, vine cuttings were brought to Santiago del Estero in 1557, and the cultivation of the grape and wine production stretched first to neighboring regions, and then to other parts of the country."
                    link="https://winesofargentina.org/en"
                    linkTitle="Go to https://winesofargentina.org/en"
                />
                <DoubleColumnItem 
                    order
                    image={australia}
                    id="australia"
                    title="Wine Region"
                    subtitle="Australia"
                    description="The Australian wine industry is one the world's largest exporters of wine, with approximately 800 million out of the 1.2 to 1.3 billion litres produced annually exported to overseas markets. The wine industry is a significant contributor to the Australian economy through production, employment, export and tourism."
                    link="https://www.wineaustralia.com/"
                    linkTitle="Go to https://www.wineaustralia.com/"
                />
                <DoubleColumnItem 
                    image={newZealand}
                    id="new-zealand"
                    title="Wine Region"
                    subtitle="New Zealand"
                    description="New Zealand wine is produced in several winegrowing regions of New Zealand. The country's elongated island geography in the South Pacific Ocean results in maritime climates with considerable regional variation from north to south. Like many other New World wines, it is usually produced and labelled as single varietal wines, or if blended the varietal components are listed on the label. New Zealand is best known for its Marlborough Sauvignon Blanc, and more recently its dense, concentrated Pinot Noir from Marlborough, Martinborough and Central Otago."
                    link="https://www.nzwine.com/"
                    linkTitle="Go to https://www.nzwine.com/"
                />
            </React.Fragment>
        )
    }
}

export default WineRegionsSection