import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import NewsListSection from '../components/sections/NewsListSection'
import NeedHelpSection from '../components/sections/NeedHelpSection'

class News extends React.Component{

    renderPageContent(){
        return(
            <Nav>
                <HeadingSection 
                    headline="Latest Industry News"
                    text="Find the latest news on our exciting wine brands."
                />
                <NewsListSection />
                <NeedHelpSection />
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Indigo Wine Group | Latest Industry News</title>
                    <meta name="description" content="Latest industry news on our exciting international wine brands. Imported and distributed throughout the USA by Indigo Wine Group." />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

export default News