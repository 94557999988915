import React from 'react'
import {Link} from 'react-router-dom'
import indigoLogo from '../assets/img/indigo-logo.svg'

class Logo extends React.Component {
    render(){
        return(
            <Link to="/" className="in_logo" title="Go To Indigo Wine Group Home">
                <figure>
                    <img src={indigoLogo} alt="Indigo Wine Group" />
                </figure>
            </Link>
        )
    }
}

export default Logo