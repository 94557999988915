import React from 'react'
import Helmet from 'react-helmet'
import Logo from '../components/Logo'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import image from '../assets/img/age-gate@2x.jpg'
import {ButtonLink} from '../components/buttons'

class PageNotFound extends React.Component{

    renderPageContent(){
        return(
            <div className={`in_agegate in_show`}>
                <Container fluid>
                    <Row>
                        <Col sm={12} md={6} xl={7} className="in_left">
                            <aside>
                                <Logo />
                                <h1 className="in_heading">
                                    <span className="in_heading-1" style={{
                                        fontSize: '150px'
                                    }}>404</span><br /><span className="in_heading-3">Sorry, the page you are looking for could not be found.</span></h1>
                                <div className="in_verify-panel">
                                    <div className="in_inner">
                                        <ButtonLink 
                                            text="Go Home"
                                            link="/"
                                        />
                                    </div>
                                </div>
                            </aside>                                    
                        </Col>
                        <Col sm={12} md={6} xl={5} className="in_right">
                            <div className="in_image" style={{
                                backgroundImage: `url(${image})`
                            }}></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Indigo Wine Group | 404 Page Not Found</title>
                    <meta name="description" content="Indigo Wine Group imports and distributes the finest range of international wine brands throughout the United States." />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

export default PageNotFound