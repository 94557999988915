import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {connect} from 'react-redux'
import {
    fetchEmployeeResources
} from '../../actions'
import {BASE_URL} from '../../path'
import {HR} from '../ui'
import {
    ButtonLink
} from '../buttons'

class EmployeeResources extends React.Component {

    componentDidMount(){
        this.props.fetchEmployeeResources(this.props.token, this.props.searchName)
    }

    renderList(title){
        console.log(this.props.data[title])
        return this.props.data[title].map((item, index) => {
            return(
                <li className="in_list-item">
                    <a href={BASE_URL + item.pdf} className="in_paragraph" title="download PDF" download target="_blank" rel="noopener noreferrer">{item.title}</a>
                </li>
            )
        })
    }

    renderTitles(){
        return Object.keys(this.props.data).map((title, index) => {
            return (
                <Col sm={12} key={index}>                    
                    {
                        this.props.data[title].length > 0 && (
                            <>
                                <h2 className="in_heading-3-1 in_heading">{title}</h2>
                                <ul>
                                    {this.renderList(title)}
                                </ul>
                            </>
                        )
                    }
                </Col>                
            )
        })
    }

    render(){
        return(
            <React.Fragment>
                <Container>
                    <Row>
                        <Col sm={12} className="text-center mt-5">
                            <div  className="in_employment-heading-col">
                                <ButtonLink text="Back" link="/employee" />
                                <h1 className="in_heading-1">{this.props.headline}</h1>
                            </div>
                            
                        </Col>
                    </Row>
                </Container>
                <HR />
                <Container>
                    <Row>
                        {
                            this.props.data && this.renderTitles()
                        }
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return{
        data: state.employeeResourcesState[ownProps.searchName]
    }
}

export default connect(mapStateToProps, {
    fetchEmployeeResources
})(EmployeeResources)