import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import EmployeeAuthForm from './EmployeeAuthForm'
import {connect} from 'react-redux'
import {
    employeeSignin
} from '../../actions'

class EmployeeAuth extends React.Component {

    onSubmit = formValues => {
        this.props.employeeSignin(formValues)
    }

    render(){
        return(
            <React.Fragment>
                <Container>
                    <Row>
                        <Col className="text-center mt-5">
                            <h1 className="in_heading-3-1">Sign in to enter Employee Resources</h1>
                            <p className="in_paragraph">This content is restricted to Indigo Employees.</p>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={12} md={6} className="mt-5">
                            <EmployeeAuthForm onSubmit={this.onSubmit} />
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

export default connect(null, {
    employeeSignin
})(EmployeeAuth)