import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TeamItem from '../items/TeamItem'
import {fetchTeam} from '../../actions'
import {connect} from 'react-redux'
import {BASE_URL} from '../../path'

class TeamSection extends React.Component{

    componentDidMount(){
        this.props.fetchTeam()
    }

    renderListWithImage(){
        return this.props.team.map((item, index) => {
            if(item.image){
                return (
                    <Col sm={12} md={6} lg={4} className="mb-5" key={index}>
                        <TeamItem 
                            image={`${BASE_URL}${item.image}`}
                            jobtitle={item.position}
                            fullname={`${item.firstname} ${item.lastname}`}
                            email={item.email}
                            alt={`${item.firstname} ${item.lastname}`}
                        />                            
                    </Col>
                )
            }        
            return <React.Fragment key={index}></React.Fragment>    
        })
    }

    renderListWithoutImage(){
        return this.props.team.map((item, index) => {
            if(!item.image){
                return (
                    <Col sm={12} md={6} lg={4} className="mb-5" key={index}>
                        <TeamItem 
                            jobtitle={item.position}
                            fullname={`${item.firstname} ${item.lastname}`}
                            email={item.email}
                            alt={`${item.firstname} ${item.lastname}`}
                        />                            
                    </Col>
                )
            }       
            return <React.Fragment key={index}></React.Fragment>      
        })
    }

    render(){
        return(
            <section className="in_team-section">
                <Container>
                    <Row>
                        {this.renderListWithImage()}
                    </Row>
                </Container>
                <Container>
                    <Row>
                        {this.renderListWithoutImage()}
                    </Row>
                </Container>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        team: state.teamState
    }
}

export default connect(mapStateToProps, {
    fetchTeam
})(TeamSection)