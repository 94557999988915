import React from 'react'
import Helmet from 'react-helmet'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NewsItem from '../items/NewsItem'
import {connect}  from 'react-redux'
import {
    fetchBrandNewsAndAccolades
} from '../../actions'
import Loader from '../Loader'
import {BASE_URL} from '../../path'

class BrandNewsAndAccoladesTab extends React.Component {

    componentDidMount(){
        this.props.fetchBrandNewsAndAccolades(this.props.brand)
    }

    renderList(){
        if(!this.props.newsAndAccolades.length > 0){
            return(
                <Col sm={12} className="mb-5">
                    <p className="in_heading-6">No News & Accolades available for this brand</p>
                </Col>
            )
        }
        return this.props.newsAndAccolades.map((item, index) => {
            return(
                <Col sm={12} md={6} lg={4} className="mb-5" key={index}> 
                    <NewsItem 
                        image={`${BASE_URL}${item.image}`}
                        text={item.title}
                        link={`${BASE_URL}${item.pdf}`}
                        alt={item.title}
                    />
                </Col>
            )
        })
    }

    render(){
        if(!this.props.newsAndAccolades || !this.props.data) { return <Loader /> }
        return(
            <React.Fragment>
                <Helmet>
                    <title>Indigo Wine Group | {this.props.data.title} News & Accolades</title>
                    <meta name="description" content={this.props.data.meta_description_news_and_accolades ? this.props.data.meta_description_news_and_accolades : 'We are dedicated to developing and maintaining strong partnerships with superior wine brands of exceptional value.'} />
                </Helmet>
                <h1 className="in_heading-3">News & Accolades</h1>
                <section className="in_accolades-list-section">
                    <Row>
                        {this.renderList()}
                    </Row>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return{
        newsAndAccolades: state.brandNewsAndAccoladesState[ownProps.brand]
    }
}

export default connect(mapStateToProps, {
    fetchBrandNewsAndAccolades
})(BrandNewsAndAccoladesTab)