import React from 'react'

class Loader extends React.Component {
    render(){
        return(
            <div className="in_loader">
                <div className="lds-facebook"><div></div><div></div><div></div></div>
            </div>
        )
    }
}

export default Loader