import React from "react";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import left from '../assets/img/iconmonstr-angel-left-thin-64.png'
import right from '../assets/img/iconmonstr-angel-right-thin-64.png'

export default class Carousel extends React.Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 750,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: false,
      pauseOnFocus: false,
      className: 'in_carousel',
      nextArrow: <CarouselNextArrow />,
      prevArrow: <CarouselPrevArrow />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    };
    return (
      <div>
        <Slider {...settings}>
            {this.props.children}
        </Slider>
      </div>
    );
  }
}

function CarouselNextArrow(props) {
  const { onClick, className } = props;
  return (
    <div
      className={`in_next-button in_carousel-button ${className}`}
      onClick={onClick}
      title="view next"
    >
      <img src={right} alt="next" />
    </div>
  );
}

function CarouselPrevArrow(props) {
  const { onClick, className } = props;
  return (
    <div
      className={`in_prev-button in_carousel-button ${className}`}
      onClick={onClick}
      title="view previous"
      >
      <img src={left} alt="prev" />
    </div>
  );
}