import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ButtonLink} from '../buttons'
import {HeadingSecondary} from '../headings'
import Carousel from '../Carousel'
import CarouselItem from '../CarouselItem'
import { HashLink as Link } from 'react-router-hash-link';
import AnimateScale from '../AnimateScale'

//temp images
import southAfrica from '../../assets/img/south-africa-map@2x.png'
import argentina from '../../assets/img/argentina-map@2x.png'
import australia from '../../assets/img/australia-map@2x.png'
import newZealand from '../../assets/img/new-zealand-map@2x.png'


class DynamicPortfolioSection extends React.Component{
    render(){
        return(
            <section className="in_dynamic-portfolio-section">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <HeadingSecondary 
                                // text="Dynamic Portfolio Of Fine Wine Brands"
                                text="Explore The Wines We Source From"
                            />
                        </Col>
                    </Row>
                    
                </Container>
                <Container className="in_mobile">
                    <Carousel>
                        <CarouselItem 
                            image={southAfrica}
                            heading="South Africa"
                            link="/portfolio#in_south-africa"
                        />  
                        <CarouselItem 
                            image={argentina}
                            heading="Argentina"
                            link="/portfolio#argentina"
                        />
                        <CarouselItem 
                            image={australia}
                            heading="Australia"
                            link="/portfolio#australia"
                        />
                        <CarouselItem 
                            image={newZealand}
                            heading="New Zealand"
                            link="/portfolio#new-zealand"
                        />
                    </Carousel>
                </Container>

                <Container className="in_widescreen in_countries-container">
                    <Row>
                        <div className="in_item col-lg-3">
                            <AnimateScale>
                                <Link scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}  to="/portfolio#in_south-africa" title="Go To South African Portfolio of Indigo Wine Group" >
                                    <img src={southAfrica} alt="South African Portfolio of Indigo Wine Group" />
                                    <h4 className="in_heading-3-1">South Africa</h4>
                                </Link>   
                            </AnimateScale>
                                                     
                        </div>
                        <div className="in_item col-lg-3">
                            <AnimateScale>
                                <Link scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}  to="/portfolio#argentina" title="Go To Argentinian Portfolio of Indigo Wine Group">
                                    <img src={argentina} alt="Argentina Portfolio of Indigo Wine Group" />
                                    <h4 className="in_heading-3-1">Argentina</h4>
                                </Link>
                            </AnimateScale>
                        </div>
                        <div className="in_item col-lg-3">
                            <AnimateScale>
                                <Link scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}  to="/portfolio#australia" title="Go To Australian Portfolio of Indigo Wine Group" >
                                    <img src={australia} alt="Australian Portfolio of Indigo Wine Group" />
                                    <h4 className="in_heading-3-1">Australia</h4>
                                </Link>
                            </AnimateScale>
                        </div>
                        <div className="in_item col-lg-3">
                            <AnimateScale>
                                <Link scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })} to="/portfolio#new-zealand" title="Go To New Zealand Portfolio of Indigo Wine Group">
                                    <img src={newZealand} alt="New Zealand Portfolio of Indigo Wine Group" />
                                    <h4 className="in_heading-3-1">New Zealand</h4>
                                </Link> 
                            </AnimateScale>
                        </div>
                    </Row>
                </Container>

                <Container>
                    <Row>
                        <Col sm={12} className="text-center">
                            <ButtonLink text="Portfolio" link="/portfolio" />
                        </Col>
                    </Row>
                </Container>

                
            </section>
        )
    }
}

export default DynamicPortfolioSection