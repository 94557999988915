import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'

//sections
import YearsOfExperience25Section from '../components/sections/YearsOfExperience25Section'
import OurVisionSection from '../components/sections/OurVisionSection'
import OurMissionSection from '../components/sections/OurMissionSection'
import NeedHelpSection from '../components/sections/NeedHelpSection'


//components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class About extends React.Component{

    renderPageContent(){
        return(
            <Nav>
                <section className="in_heading-section text-center">
                    <Container>
                        <Row>
                            <Col>
                                <h2 className="in_heading-1 text-center">Indigo Wine Group</h2>
                                <p className="in_paragraph">The Indigo Wine Group was formed in 2002, as a partnership between Robertson Winery from South Africa, and Richard Gupta, a wine importer living in Venice, Florida.</p>
                                    
                                <p className="in_paragraph">Initially, their objective was to introduce one of South Africa’s largest wineries, Robertson Winery to distributors in the United States. Basing the company in Venice, Florida, they utilized the services of a beverage logistics company in order to keep their focus on sales and marketing and commenced setting up a distributor network.</p>
                                
                                <p className="in_paragraph">With the business developing at a rapid pace, it became apparent to the partners that in order to become a more meaningful supplier to their distribution partners, Indigo needed to offer a more well-rounded portfolio of products. Today Indigo presents a dynamic portfolio of wine brands, selling wine to a network of distributors throughout the United States. The partners also made a decision to take Indigo to the next level of service, recreating it as a fully-integrated wine importing company.</p>
                            </Col>
                        </Row>
                    </Container>

                    <YearsOfExperience25Section />
                    
                </section>

                <OurVisionSection />

                <OurMissionSection />

                <NeedHelpSection />

            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Indigo Wine Group | About</title>
                    <meta name="description" content="Indigo Wine Group is a full-integrated wine importing company offering a dynamic portfolio of international wine brands to a network of distributors throughout the United States." />
                </Helmet>
                {this.renderPageContent()}                
            </React.Fragment>
        )
    }
}

export default About