import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'
import Recaptcha from 'react-recaptcha'

class ContactForm extends React.Component {

    state = {
        capchaLoaded: null,
        capchaVerified: null
    }

    renderError({error, touched}){
		if(error && touched){
			return(
                <div className="in_error mt-2" role="alert">
                    {error}
                </div>
			)
		}
	}

    renderInput = ({input, label, meta, type}) => {
        const className = `form-group ${meta.error && meta.touched ? 'in_error' : ''}`

        return (
            <div className={className}>
                <input type={type} className="in_form-control" {...input} autoComplete="off" placeholder={label} />
                {this.renderError(meta)}
            </div>
        )
    }

    renderSelect = ({input, children, meta}) => {
        const className = `form-group ${meta.error && meta.touched ? 'in_error' : ''}`
        return (
            <div className={className}>
                <select className="in_form-control" {...input}>
                    {children}
                </select>
                {this.renderError(meta)}
            </div>
        )
    }

    renderTextArea = ({input, label, meta}) => {
        const className = `form-group ${meta.error && meta.touched ? 'in_error' : ''}`
        return (
            <div className={className}>
                <textarea className="in_form-control" {...input} placeholder={label} rows="3"></textarea>
                {this.renderError(meta)}
            </div>
        )
    }

    callback = () => {
        console.log('recapcha has loaded')
        this.setState({
            capchaLoaded: true
        })
    }

    verifyCallback = response => {
        console.log('recapcha has been verified')
        this.setState({
            capchaVerified: true
        })
    }

    onSubmit = formValues => {
        if(this.state.capchaLoaded){
            if(this.state.capchaVerified){
                this.props.onSubmit(formValues)
                setTimeout(() => { 
                    var element = document.getElementById('in_thankyou')
                    var headerOffset = 200
                    var elementPosition = element.offsetTop
                    var offsetPosition = elementPosition - headerOffset

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth"
                    })
                }, 300)
                
            } else {
                alert('Please verify you are not a robot.')
            }
        } else {
            this.props.onSubmit(formValues)
        }        
    }

    render(){
        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="in_form">

                <Field name="fullname" type="text" component={this.renderInput} label="Full Name" />

                <Field name="email" type="email" component={this.renderInput} label="E-mail" />

                <Field name="interest" component={this.renderSelect} label="I am interested in">
                    <option value="">Please select a question</option>
                    <option>Where can I purchase wine in my area</option>
                    <option>I am a consumer looking for product in my area</option>
                    <option>I am a distributor or retailer looking for more information</option>
                    <option>None of the above</option>
                </Field>

                <Field name="message" component={this.renderTextArea} label="Message" />

                <Recaptcha
                    sitekey="6LfEGcYZAAAAAHJQNIjknMn0KEgGUGLuvdjUFu5B"
                    render="explicit"
                    onloadCallback={this.callback}
                    verifyCallback={this.verifyCallback}
                /> 

                <div className="form-group in_buttom-container">
                    <button className="in_button mt-3">Submit</button>
                </div>               
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    if(!formValues.fullname){
        errors.fullname = "You must enter your full name"
    }
    if(!formValues.email){
        errors.email = "You must enter your e-mail address"
    } else if(!formValues.email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
        errors.email = "You must enter a valid e-mail address"
    }
    if(!formValues.interest){
        errors.interest = "You must select an interest"
    }
    if(!formValues.message){
        errors.message = "You must enter a message"
    }
    return errors
}

export default reduxForm({
    form: 'contact',
    validate
})(ContactForm)