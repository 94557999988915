import React from 'react'

class YoutubeItem extends React.Component {
    
    render(){
        const {title, link} = this.props
        return(
            <div className="in_youtube-container">
                <iframe title={title} width="560" height="315" src={`https://www.youtube.com/embed/${link}`} frameBorder="0" allowFullScreen></iframe>
            </div>
        )
    }
}

export default YoutubeItem