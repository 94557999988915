import {
    FETCH_BRAND_OVERVIEW
} from '../actions/types'

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_BRAND_OVERVIEW:
            return {
                ...state,
                [action.searchName]: action.payload.title ? action.payload : null
            }
        default:
            return state
    }
}