import indigo from '../api'
import {
    FETCH_BRANDS,
    FETCH_BRANDS_COUNTRIES,
    FETCH_BRAND_OVERVIEW,
    FETCH_BRAND_FULL_RANGE,
    FETCH_BRAND_NEWS_AND_ACCOLADES,
    FETCH_BRAND_SLIDE_PRESENTATIONS,
    FETCH_BRAND_RANGES,
    FETCH_PRODUCT,
    FETCH_NEWS,
    FETCH_PRODUCT_RESOURCES,
    FETCH_TEAM,
    FETCH_GALLERY_IMAGES,
    FETCH_PRODUCT_SLIDER_PRESENTATIONS,
    VERYFY_AGE,
    EMPLOYEE_SIGNIN,
    EMPLOYEE_SIGNOUT,
    FETCH_EMPLOYEE_RESOURCES,
    SEND_CONTACTFORM_EMAIL,
    SEND_CONTACTFORM_EMAIL_ERROR
} from './types'

import history from '../history'

export const fetchBrands = () => async dispatch => {
    const response = await indigo.get('brands')
    dispatch ({
        type: FETCH_BRANDS,
        payload: response.data
    })
}

export const fetchBrandsCountries = () => async dispatch => {
    const response = await indigo.get('brands/countries')
    dispatch ({
        type: FETCH_BRANDS_COUNTRIES,
        payload: response.data
    })
}

export const fetchBrandOverview = searchName => async dispatch => {
    
    try {
        const response = await indigo.get(`brands/${searchName}`)
        dispatch ({
            type: FETCH_BRAND_OVERVIEW,
            payload: response.data,
            searchName
        })
    } catch (error){
        dispatch ({
            type: FETCH_BRAND_OVERVIEW,
            payload: null,
            searchName
        })
    }
}

export const fetchBrandFullRange = searchName => async dispatch => {
    
    try {
        const response = await indigo.get(`brands/${searchName}/products`)
        dispatch ({
            type: FETCH_BRAND_FULL_RANGE,
            payload: response.data,
            searchName
        })
    } catch(error){
        dispatch ({
            type: FETCH_BRAND_FULL_RANGE,
            payload: null,
            searchName
        })
    }
}

export const fetchBrandNewsAndAccolades = searchName => async dispatch => {
    const response = await indigo.get(`brands/${searchName}/news-and-accolades`)
    dispatch ({
        type: FETCH_BRAND_NEWS_AND_ACCOLADES,
        payload: response.data,
        searchName
    })
}

export const fetchBrandSlidePresentations = searchName => async dispatch => {
    try{
        const response = await indigo.get(`brands/${searchName}/slide-presentations`)
        dispatch ({
            type: FETCH_BRAND_SLIDE_PRESENTATIONS,
            payload: response.data,
            searchName
        })
    } catch(error){
        dispatch ({
            type: FETCH_BRAND_SLIDE_PRESENTATIONS,
            payload: null,
            searchName
        })
    }
}

export const fetchProductSlidePresentations = searchName => async dispatch => {
    const response = await indigo.get(`products/${searchName}/slide-presentations`)
    dispatch ({
        type: FETCH_PRODUCT_SLIDER_PRESENTATIONS,
        payload: response.data,
        searchName
    })
}

export const fetchBrandRanges = searchName => async dispatch => {
    const response = await indigo.get(`brands/${searchName}/ranges`)
    dispatch ({
        type: FETCH_BRAND_RANGES,
        payload: response.data,
        searchName
    })
}

export const fetchProduct = searchName => async dispatch => {
    const response = await indigo.get(`products/${searchName}`)
    dispatch ({
        type: FETCH_PRODUCT,
        payload: response.data,
        searchName
    })
}

export const fetchProductResources = searchName => async dispatch => {
    const response = await indigo.get(`products/${searchName}/resources`)
    dispatch ({
        type: FETCH_PRODUCT_RESOURCES,
        payload: response.data,
        searchName
    })
}

export const fetchNews = searchName => async dispatch => {
    const response = await indigo.get(`news/${searchName}`)

    dispatch ({
        type: FETCH_NEWS,
        payload: response.data,
        searchName
    })
}

export const fetchTeam = () => async dispatch => {
    const response = await indigo.get('team')

    dispatch ({
        type: FETCH_TEAM,
        payload: response.data
    })
}

export const fetchBrandGallery = searchName => async dispatch => {
    const response = await indigo.get(`brands/${searchName}/gallery`)

    dispatch ({
        type: FETCH_GALLERY_IMAGES,
        payload: response.data,
        searchName
    })
}

export const veryfyAge = () => {
    return (dispatch) => {
        dispatch({
            type: VERYFY_AGE
        })
    }
}

export const employeeSignin = formValues => async dispatch => {

    const response = await indigo.post('login', formValues)

    dispatch ({
        type: EMPLOYEE_SIGNIN,
        payload: response.data
    })
}

export const employeeSignout = token => async dispatch => {

    const config = { 
        headers: { 
            Authorization: `Bearer ${token}` 
        } 
    };

    const data = {}

    const response = await indigo.post('logout', data, config)

    dispatch ({
        type: EMPLOYEE_SIGNOUT,
        payload: response.data
    })
}

export const fetchEmployeeResources = (token, searchName) => async dispatch => {

    const config = { 
        headers: { 
            Authorization: `Bearer ${token}` 
        } 
    };

    const response = await indigo.get(`employee-resources/${searchName}`, config)

    dispatch ({
        type: FETCH_EMPLOYEE_RESOURCES,
        payload: response.data,
        searchName
    })
}

export const contactFormEmail = formValues => async dispatch => {

    try{
        const response = await indigo.post('email/contact', formValues)

        dispatch ({
            type: SEND_CONTACTFORM_EMAIL,
            payload: response.data,
        })

        history.push('/thankyou')
        
    } catch(error){
        dispatch ({
            type: SEND_CONTACTFORM_EMAIL_ERROR,
            payload: error,
        })
    }
}