import React from 'react'
import Helmet from 'react-helmet'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {connect} from 'react-redux'
import {
    fetchBrandRanges
} from '../../actions'
import Loader from '../Loader'
import {BASE_URL} from '../../path'
import ProductItem from '../items/ProductItem'

class BrandRangeTab extends React.Component {

    componentDidMount(){
        this.props.fetchBrandRanges(this.props.brand)
    }

    renderRangeList(range){
        return this.props.brandRanges[range].map((item, index) => {
            return(                
                <Col sm={12} md={6} lg={4} className="mb-5" key={index}>
                    <ProductItem 
                        text={item.title}
                        image={`${BASE_URL}${item.image}`}
                        link={`/portfolio/${this.props.brand}/range/${item.search_name}`}
                        alt={item.title}
                    />                          
                </Col>
            )
        })
    }

    renderRanges(){
        return Object.keys(this.props.brandRanges).map((range, index) => {
            return (
                <div className="in_range-sub-wrapper" key={index}>
                    <h1 className="in_heading-3">{range}</h1>
                    <section className="in_product-range-list-section">
                        <Row>
                            {this.renderRangeList(range)}
                        </Row>
                    </section>
                </div>
            )
        })
    }

    render(){
        if(!this.props.brandRanges || !this.props.data){ return <Loader /> }
        return(
            <React.Fragment>
                <Helmet>
                    <title>Indigo Wine Group | {this.props.data.title} Range</title>
                    <meta name="description" content={this.props.data.meta_description_ranges ? this.props.data.meta_description_ranges : 'We are dedicated to developing and maintaining strong partnerships with superior wine brands of exceptional value.'} />
                </Helmet>
                <div className="in_range-wrapper">
                    {this.renderRanges()}
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        brandRanges: state.brandRangesState[ownProps.brand]
    }
}

export default connect(mapStateToProps, {
    fetchBrandRanges
})(BrandRangeTab)