import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom'

class EmployeeLanding extends React.Component {

    render(){
        return(
            <React.Fragment>
                <Container>
                    <Row>
                        <Col sm={12} className="text-center mt-5">
                            <h1 className="in_heading-1">Employee Resources Page</h1>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col xs={12} sm={6} md={4} lg={3} className="text-center mt-3">
                            <Link to="/employee/depletion-reports" className="in_employee-item">
                                <h2 className="in_heading-3-1 in_heading">Depletion<br />Reports</h2>
                                <div className="in_download-link">Enter</div>
                            </Link>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} className="text-center mt-3">
                            <Link to="/employee/marketing-spread-sheet" className="in_employee-item">
                                <h2 className="in_heading-3-1 in_heading">Marketing<br />Spread Sheet</h2>
                                <div className="in_download-link">Enter</div>
                            </Link>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} className="text-center mt-3">
                            <Link to="/employee/holiday-list" className="in_employee-item">
                                <h2 className="in_heading-3-1 in_heading">Holiday<br />List</h2>
                                <div className="in_download-link">Enter</div>
                            </Link>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} className="text-center mt-3">
                            <Link to="/employee/vacation-request" className="in_employee-item">
                                <h2 className="in_heading-3-1 in_heading">Vacation<br />Request</h2>
                                <div className="in_download-link">Enter</div>
                            </Link>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} className="text-center mt-3">
                            <Link to="/employee/expense-report" className="in_employee-item">
                                <h2 className="in_heading-3-1 in_heading">Expense<br />Report</h2>
                                <div className="in_download-link">Enter</div>
                            </Link>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} className="text-center mt-3">
                            <Link to="/employee/mileage-report" className="in_employee-item">
                                <h2 className="in_heading-3-1 in_heading">Mileage<br />Log</h2>
                                <div className="in_download-link">Enter</div>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

export default EmployeeLanding