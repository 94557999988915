import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class WhereToBuy extends React.Component{

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Indigo Wine Group | Where To Buy</title>
                    <meta name="description" content="" />
                </Helmet>
                <Nav>
                    <HeadingSection 
                        headline="Where To Buy"
                        text="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam nulla necessitatibus animi doloribus eaque recusandae tenetur distinctio. Nesciunt eaque reiciendis temporibus soluta odio laboriosam eos? Numquam impedit accusamus repellendus beatae."
                    />
                    <section style={{paddingTop: '0'}}>
                        <Container>
                            <Row>
                                <Col sm={12}>
                                    <iframe title="where-to-buy" className="_iwg-where-to-buy" src="https://finder.vtinfo.com/finder/web/v2/iframe?custID=VIP&theme=bs-paper" width="650" height="610" frameborder="0" scrolling="no" allow="geolocation;"></iframe>                                 
                                </Col>
                            </Row>
                        </Container>
                    </section>  
                    
                </Nav>
                
            </React.Fragment>
        )
    }
}

export default WhereToBuy