import React from 'react'
import {NavLinksWidescreenMenu, SocialMediaIconsLinks} from './NavLinks'
import Logo from './Logo'
import {connect} from 'react-redux'
import {
    fetchBrands
} from '../actions'

class WidescreenMenu extends React.Component {

    componentDidMount(){
        this.props.fetchBrands()
    }
    render(){
        if(!this.props.brands){return <></>}
        return(
            <nav className="in_widescreen-side-menu">
                <aside className="in_aside">
                    <Logo />
                    <p className="in_paragraph">1501 S. Tamiami Tr. #501, Venice, Florida 34285, USA</p>
                    <ul className="in_ul-social">
                        <SocialMediaIconsLinks />
                    </ul>
                </aside>
                <ul className="in_ul">
                    <NavLinksWidescreenMenu onClick={this.props.onClick} brands={this.props.brands} />
                </ul>
                <div className="clearfix"></div>
            </nav>
        )
    }
}

const mapStateToProps = state => {
    return {
        brands: state.brandsState
    }
}

export default connect(mapStateToProps, {
    fetchBrands
})(WidescreenMenu)