import React from 'react'
import {veryfyAge} from '../actions'
import {connect} from 'react-redux'
import AgeGatePortal from './AgeGatePortal'

class AgeGate extends React.Component {

    state = {
        show: false
    }

    componentDidMount(){
        setTimeout(() => {
            this.setState({show: true})
        }, 5000);
    }

    onVerifyAge = () => {
        this.props.veryfyAge()
    }

    render(){
        return(
            <AgeGatePortal 
                onClick={this.onVerifyAge}
                show={this.state.show}
            />
        )
    }
}

export default connect(null, {
    veryfyAge
})(AgeGate)