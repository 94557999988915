import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import PortfolioSection from '../components/sections/PortfolioSection'
import NeedHelpSection from '../components/sections/NeedHelpSection'

class Portfolio extends React.Component{

    renderPageContent(){
        return(
            <Nav>
                <HeadingSection 
                    headline="Our Wine Portfolio"
                    text="We are dedicated to developing and maintaining strong partnerships with superior wine brands of exceptional value."
                />              
                <PortfolioSection />
                <NeedHelpSection />
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Indigo Wine Group | Portfolio</title>
                    <meta name="description" content="Indigo Wine Group imports and distributes South African wines from Robertson Winery, Constitution Road, Groot Constantia, Rustenberg Wines, Barista, Fairview and Krone, Argentine wines from Casa de Campo and New Zealand wines from Steve Bird Wines throughout the USA." />
                </Helmet>
                {this.renderPageContent()}                 
            </React.Fragment>
        )
    }
}

export default Portfolio