import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//images
import critical from '../../assets/img/critical-partner.svg'
import quality from '../../assets/img/quality-products.svg'
import respected from '../../assets/img/respected-partner.svg'





class OurMissionSection extends React.Component{
    render(){
        return(
            <section className="in_our-mission-section">
                <Container>
                    <Row>
                       <Col sm={12}>
                           <h2 className="in_heading-1">Our Mission</h2>
                           <p className="in_paragraph">Indigo Wine Group will work to become the most successful South African wine importer, offering the impressive quality and value of South African wines; while also providing a select and quality driven international portfolio.</p>
                       </Col>
                       <Col sm={12} md={6} lg={4} className="in_animate-lift">
                           <figure>
                                <img src={critical} alt="Critical Partner" />
                           </figure>
                           <h3 className="in_heading-7">Critical Partner</h3>
                           <p className="in_paragraph">Becoming a critical indispensable partner to a chosen group of successful wine producers and key distributors.</p>
                       </Col>
                       <Col sm={12} md={6} lg={4} className="in_animate-lift">
                           <figure>
                                <img src={quality} alt="Respected Partners" />
                           </figure>
                           <h3 className="in_heading-7">Respected Partners</h3>
                           <p className="in_paragraph">Being respected by the wine industry and the trade for the quality of our portfolio, people, service excellence, and integrity.</p>
                       </Col>
                       <Col sm={12} md={6} lg={4} className="in_animate-lift">
                           <figure>
                                <img src={respected} alt="Quality Products" />
                           </figure>
                           <h3 className="in_heading-7">Quality Products</h3>
                           <p className="in_paragraph">Developing the South African wine category with our distributors through our knowledge and understanding of the category. And assurance all products, South African and international, imported by Indigo Wine Group carry the same standard of integrity.</p>
                       </Col>
                    </Row>                    
                </Container>
            </section>
        )
    }
}

export default OurMissionSection