import React from 'react'

class NewsItem extends React.Component {
    
    render(){
        const {image, link, text, alt} = this.props
        return(
            <div className="in_news-item">
                <figure>
                    <img src={image} alt={alt} />
                    <figcaption>
                        <h3 className="in_heading-7">{text}</h3>
                    </figcaption>
                    <a className="in_download-link" href={link} download={text} target="_blank" rel="noopener noreferrer">VIEW PDF</a>
                </figure>    
            </div>
        )
    }
}

export default NewsItem