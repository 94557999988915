import React from 'react'
import {NavLink, Link} from 'react-router-dom'
import facebookIcon from '../assets/img/iconmonstr-facebook-4-240.png'
import emailIcon from '../assets/img/iconmonstr-email-10-240.png'
import instagramIcon from '../assets/img/iconmonstr-instagram-9-64-indigo.png'
import { HashLink as AnchorLink } from 'react-router-hash-link';

export class NavLinksWidescreen extends React.Component {

    render(){
        return(
            <React.Fragment>
                <li>
                    <NavLink exact to="/" activeClassName="in_active">Home</NavLink>
                </li>
                <li>
                    <NavLink to="/about" activeClassName="in_active">About</NavLink>
                </li>
                <li>
                    <NavLink to="/portfolio" activeClassName="in_active">Portfolio</NavLink>
                </li>
                <li>
                    <NavLink to="/accolades" activeClassName="in_active">Accolades</NavLink>
                </li>
                <li>
                    <NavLink to="/news" activeClassName="in_active">News</NavLink>
                </li>
                {/*<li>
                    <NavLink to="/social-feed" activeClassName="in_active">Social Feed</NavLink>
                </li>*/}
                <li>
                    <NavLink to="/contact" activeClassName="in_active">Contact Us</NavLink>
                </li>
            </React.Fragment>      
        )
    }
}

export class NavLinksMobile extends React.Component {
    render(){
        return(
            <React.Fragment>
                <li>
                    <NavLink to="/" activeClassName="in_active">Home</NavLink>
                </li>
                <li>
                    <NavLink to="/about" activeClassName="in_active">About</NavLink>
                </li>
                <li>
                    <NavLink to="/portfolio" activeClassName="in_active">Portfolio</NavLink>
                </li>
                <li>
                    <NavLink to="/news" activeClassName="in_active">News</NavLink>
                </li>
                <li>
                    <NavLink to="/accolades" activeClassName="in_active">Accolades</NavLink>
                </li>
                <li>
                    <NavLink to="/wine-industry" activeClassName="in_active">Wine Industry</NavLink>
                </li>
                <li>
                    <NavLink to="/social-feed" activeClassName="in_active">Social Feed</NavLink>
                </li>
                <li>
                    <NavLink to="/contact" activeClassName="in_active">Contact Us</NavLink>
                </li>
            </React.Fragment>      
        )
    }
}

export class NavLinksWidescreenMenu extends React.Component {

    renderBrandItems(){
        return this.props.brands.map((item, index) => {
            return(
                <li key={index}>
                    <Link onClick={this.props.onClick} to={`/portfolio/${item.search_name}`} className="in_paragraph-3">{item.title}</Link>
                </li>
            )
        })
    }

    render(){
        
        return(
            <React.Fragment>
                <li>
                    <NavLink to="/" activeClassName="in_active" className="in_heading-7">Home</NavLink>
                </li>
                <li>
                    <NavLink to="/about" activeClassName="in_active" className="in_heading-7">About</NavLink>
                </li>
                <li>
                    <NavLink to="/portfolio" activeClassName="in_active" className="in_heading-7">Portfolio</NavLink>
                    <ul className="in_sub-ul">
                        {this.renderBrandItems()}
                        {/* <li>
                            <Link onClick={this.props.onClick} to="/" className="in_paragraph-3">Barista</Link>
                        </li> */}
                    </ul>
                </li>
                <li>
                    <NavLink to="/news" activeClassName="in_active" className="in_heading-7">News</NavLink>
                </li>
                <li>
                    <NavLink to="/accolades" activeClassName="in_active" className="in_heading-7">Accolades</NavLink>
                </li>
                <li>
                    <NavLink to="/wine-industry" activeClassName="in_active" className="in_heading-7">Wine Industry</NavLink>
                    <ul className="in_sub-ul">
                        <li>
                            <AnchorLink onClick={this.props.onClick} scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })} to="/wine-industry#south-africa" className="in_paragraph-3">South Africa</AnchorLink>
                        </li>
                        <li>
                            <AnchorLink onClick={this.props.onClick} scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })} to="/wine-industry#argentina" className="in_paragraph-3">Argentina</AnchorLink>
                        </li>
                        <li>
                            <AnchorLink onClick={this.props.onClick} scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })} to="/wine-industry#australia" className="in_paragraph-3">Australia</AnchorLink>
                        </li>
                        <li>
                            <AnchorLink onClick={this.props.onClick} scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })} to="/wine-industry#new-zealand" className="in_paragraph-3">New Zealand</AnchorLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <NavLink to="/social-feed" activeClassName="in_active" className="in_heading-7">Social Feed</NavLink>
                </li>
                <li>
                    <NavLink to="/contact" activeClassName="in_active" className="in_heading-7">Contact Us</NavLink>
                    <ul className="in_sub-ul">
                        <ContactLinks />
                    </ul>
                </li>
            </React.Fragment>      
        )
    }
}

export class ContactLinks extends React.Component {
    render(){
        return(
            <React.Fragment>
                <li>
                    <a href="tel:(941) 483-1816" title="Call us">(941) 483-1816</a>
                </li>
                <li>
                    <a href="mailto:contact@indigowinegroup.com?subject=Indigo Wine Group Enquiry" title="Send us an e-mail">contact@indigowinegroup.com</a>
                </li>
            </React.Fragment>      
        )
    }
}

export class SocialMediaIconsLinks extends React.Component {
    render(){
        return(
            <React.Fragment>
                <li>
                    <a href="https://www.facebook.com/Indigo-Wine-Group-112033393909227" target="_blank" rel="noopener noreferrer">
                        <img src={facebookIcon} alt="Facebook" />
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/indigowinegroup/" target="_blank" rel="noopener noreferrer">
                        <img src={instagramIcon} alt="Instagram" />
                    </a>
                </li>
                <li>
                    <a href="mailto:contact@indigowinegroup.com?subject=Indigo Wine Group Enquiry" target="_blank" rel="noopener noreferrer" title="Send us an e-mail">
                        <img src={emailIcon} alt="Email" />
                    </a>
                </li>
            </React.Fragment>
        )
    }
}

export class CopyWrightLinks extends React.Component {
    render(){
        return(
            <React.Fragment>
                <li className="in_copywright-links">
                    All rights reserved. Powered by <a href="https://optimalonline.co.za" target="_blank"  rel="noopener noreferrer"><strong>Optimal Online</strong></a>
                </li>
            </React.Fragment>
        )
    }
}