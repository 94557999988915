import React from 'react'
import Container from 'react-bootstrap/Container';

import our_vision from '../../assets/img/our-vision-parallax-scroll.jpg'

class OurVisionSection extends React.Component{
    render(){
        return(
            <section className="in_ourvision-section" 
                style={{
                    backgroundImage: `url(${our_vision})`
                }}
                title="Indigo Wine Group Vision"
                aria-label="Indigo Wine Group Vision"
            >
                <Container>
                    <div className="in_inner">
                        <h2 className="in_heading-1">Our Vision</h2>
                        <p className="in_paragraph-2">Indigo Wine Group imports and provides a selection of premium wine brands of exceptional quality and value to leading distributors and retailers in the USA.</p>
                    </div>
                    <div className="in_overlay"></div>
                    <div className="clearfix"></div>
                </Container>
            </section>
        )
    }
}

export default OurVisionSection