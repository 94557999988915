import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class PrivacyPolicy extends React.Component{

    renderPageContent(){
        return(
            <Nav>
                <section className="in_heading-section text-center">
                    <Container>
                        <h1 className="in_heading-1 text-center">Privacy Policy</h1>
                    </Container>
                </section>
                <section>
                    <Container>
                        <Row>
                            
                            <Col sm={12}>
                                <p className="in_paragraph">Some text here...</p>                                 
                            </Col>
                        </Row>
                    </Container>
                </section>                 
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Indigo Wine Group | Privacy Policy</title>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

export default PrivacyPolicy