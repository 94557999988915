import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BrandItem from '../items/BrandItem'
import PrivateLabelsItems from '../items/PrivateLabelsItems'
import {connect} from 'react-redux'
import {
    fetchBrandsCountries
} from '../../actions'
import {BASE_URL} from '../../path'
import Loader from '../Loader'
import southAfrica from '../../assets/img/Private-Label-South-Africa@2x.png'
import newZealand from '../../assets/img/Private-Label-New-Zealand@2x.png'
import australia from '../../assets/img/Private-Label-Australia@2x.png'

class PortfolioSection extends React.Component{

    componentDidMount(){
        this.props.fetchBrandsCountries()
    }

    renderItems(country){
        if(country){
            return this.props.brandsCountries[1][country].map((item, index) => {
                return(
                    <Col xs={6} sm={4} md={4} lg={2} className="text-center" key={index}>
                        <BrandItem 
                            text={item.title}
                            image={`${BASE_URL}${item.logo}`}
                            link={`/portfolio/${item.search_name}`}
                            alt={`${item.title}`}
                        />
                    </Col>
                )
            })
        }
        return this.props.brandsCountries[0].map((item, index) => {
            return(
                <Col xs={6} sm={4} md={4} lg={2} className="text-center" key={index}>
                    <BrandItem 
                        text={item.title}
                        image={`${BASE_URL}${item.logo}`}
                        link={`/portfolio/${item.search_name}`}
                        alt={`${item.title}`}
                    />
                </Col>
            )
        })        
    }

    renderSouthAfrica(){
        return (
            <Container className="in_p-container in_border-top" id="in_south-africa">
                <Row>
                    <Col sm={12}>
                        <h2 className="in_heading-3 mt-5">South Africa</h2>
                    </Col>
                    {this.renderItems()}
                </Row>
            </Container>
        )
    }

    renderInternational(){
        return Object.keys(this.props.brandsCountries[1]).sort().map((country, index) => {
            return (
                <Container className="in_p-container in_border-top" id={country.toLowerCase().replace(' ', '-')} key={index}>
                    <Row>
                        <Col sm={12}>
                            <h2 className="in_heading-3 mt-5">{country}</h2>
                        </Col>
                        {this.renderItems(country)}
                    </Row>
                </Container>
            )
        })
    }

    renderPrivateLabels(){
        return (
            <Container className="in_p-container in_border-top" id="private-labels">
                <Row>
                    <Col sm={12}>
                        <h2 className="in_heading-3 mt-5">Private Labels</h2>
                    </Col>
                    <Col xs={6} sm={4} md={4} lg={2} className="text-center">
                        <PrivateLabelsItems 
                            text="South Africa"
                            image={southAfrica}
                            link={require('../../assets/img/Private-Label-South-Africa.pdf')}
                            alt="South Africa"
                        />
                    </Col>
                    <Col xs={6} sm={4} md={4} lg={2} className="text-center">
                        <PrivateLabelsItems 
                            text="Australia"
                            image={newZealand}
                            link={require('../../assets/img/Private-Label-Australia-2020-1.pdf')}
                            alt="Australia"
                        />
                    </Col>
                    <Col xs={6} sm={4} md={4} lg={2} className="text-center">
                        <PrivateLabelsItems 
                            text="New Zealand"
                            image={australia}
                            link={require('../../assets/img/Private-Label-New-Zealand-2020.pdf')}
                            alt="New Zealand"
                        />
                    </Col>
                </Row>
            </Container>
        )
    }

    render(){
        if (!this.props.brandsCountries) { return <Loader /> }
        return(
            <section className="in_portfolio-section">               
                {this.props.brandsCountries[0] && this.renderSouthAfrica()}
                {this.props.brandsCountries[1] && this.renderInternational()}
                {this.renderPrivateLabels()}
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        brandsCountries: state.brandsCountriesState
    }
}

export default connect(mapStateToProps, {
    fetchBrandsCountries
})(PortfolioSection)