import React from 'react'
import {Link} from 'react-router-dom'

class ProductCarouselItem extends React.Component {
    
    render(){
        const {image, link, text, alt} = this.props
        return(
            <Link to={link} className="in_product-carousel-item in_hover-dropshadow" title={`View ${alt} Details`}>
                <figure>
                    <img src={image} alt={alt} />
                    <figcaption>
                        <h3 className="in_heading-7">{text}</h3>
                    </figcaption>
                    <div className="in_download-link">View</div>
                </figure>      
            </Link>
        )
    }
}

export default ProductCarouselItem