import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'

//sections
import TopBannerSliderSection from '../components/sections/TopBannerSliderSection'
import IndigoWinesSection from '../components/sections/IndigoWinesSection'
import DynamicPortfolioSection from '../components/sections/DynamicPortfolioSection'
import WinePortfolioSection from '../components/sections/WinePortfolioSection'
import AccoladesSection from '../components/sections/AccoladesSection'
// import SocialFeedSection from '../components/sections/SocialFeedSection'
import GetInTouchSection from '../components/sections/GetInTouchSection'

class Home extends React.Component{

    renderPageContent(){
        return(
            <Nav>
                <TopBannerSliderSection />
                <IndigoWinesSection />
                <DynamicPortfolioSection />
                <WinePortfolioSection />
                <AccoladesSection />
                {/*<SocialFeedSection/>*/}
                <GetInTouchSection />
            </Nav>
        )        
    }

    render(){

        const ldJson = {
            "@context": "https://schema.org",
            "@type": "Corporation",
            "name": "Indigo Wine Group",
            "alternateName": "Indigo Wine Group",
            "url": "https://www.indigowinegroup.com/",
            "logo": "https://www.indigowinegroup.com/static/media/indigo-logo.9e83b24e.svg",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "(941) 483-1816",
              "contactType": "sales",
              "areaServed": "US",
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.facebook.com/Indigo-Wine-Group-112033393909227",
              "https://www.instagram.com/indigowinegroup/"
            ]
        }
        return(
            <React.Fragment>
                <Helmet>
                    <title>Indigo Wine Group | Home</title>
                    <meta name="description" content="Indigo Wine Group imports and distributes the finest range of international wine brands throughout the United States." />
                    <meta name="robots" content="index, follow" />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </Helmet>
                {this.renderPageContent()} 
            </React.Fragment>            
        )
    }
}

export default Home