import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FlocklerEmbed from '../FlocklerEmbed'

class SocialFeedSection extends React.Component{
    render(){

        return(
            <section className="in_socialfeed-section">
                <Container>
                    <Row>
                        <Col>
                            <FlocklerEmbed
                            uuidFlockler="1742eb872d20234e1779588f871b6035"
                            embedCodeId="1742eada4ef01e45bae8d5a32ba49bfb/1742eb872d20234e1779588f871b6035"
                            />
                        </Col>                 
                    </Row>
                </Container>
            </section>
        )
    }
}

export default SocialFeedSection