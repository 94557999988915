import React from 'react'
import {NavLinksWidescreen} from './NavLinks'

class WidescreenNav extends React.Component {
    render(){
        return(
            <nav className="in_widescreen-nav">
                <ul className="in_unordered-list">
                    <NavLinksWidescreen />
                </ul>
            </nav>
        )
    }
}

export default WidescreenNav