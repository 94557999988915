import React from 'react'
import Helmet from 'react-helmet'
import {HR} from '../ui'
import ProductCarousel from '../ProductCarousel'
import ProductCarouselItem from '../items/ProductCarouselItem'
import ProductItem from '../items/ProductItem'
import YoutubeItem from '../items/YoutubeItem'
import NewsItem from '../items/NewsItem'
import {connect} from 'react-redux'
import {
    fetchBrandFullRange,
    fetchBrandSlidePresentations
} from '../../actions'
import Loader from '../Loader'
import {BASE_URL} from '../../path'
import {getLastSegmentOfURL} from '../../functions'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageNotFound from '../../pages/PageNotFound'

class BrandOverviewTab extends React.Component {

    componentDidMount(){
        this.props.fetchBrandFullRange(this.props.brand)
        this.props.fetchBrandSlidePresentations(this.props.brand)        
    }

    componentDidUpdate(prevProps){
        if(this.props.brand !== prevProps.brand){
            this.props.fetchBrandFullRange(this.props.brand)
            this.props.fetchBrandSlidePresentations(this.props.brand)  
        }              
    }

    renderCarouselItems(){
        return this.props.fullRange.map((item, index) => {
            return(
                <ProductCarouselItem 
                    key={index}
                    text={item.title}
                    image={`${BASE_URL}${item.image}`}
                    link={`/portfolio/${this.props.brand}/range/${item.search_name}`}
                    alt={item.title}
                />
            )
        })
    }

    renderItems(){
        return this.props.fullRange.map((item, index) => {
            return(
                <Col sm={12} md={6} lg={4} className="mb-5" key={index}>
                    <ProductItem 
                        text={item.title}
                        image={`${BASE_URL}${item.image}`}
                        link={`/portfolio/${this.props.brand}/range/${item.search_name}`}
                        alt={item.title}
                    />
                </Col>
                
            )
        })
    }

    renderFullRange(){
        if(this.props.fullRange.length > 3){
            return(
                <>
                    <ProductCarousel>
                        {this.renderCarouselItems()}
                    </ProductCarousel>
                </>
            )
        }
        return(
            <section className="in_full-range-section">
                <Row>
                    {this.renderItems()}
                </Row>
            </section>
        )
    }

    renderSliderPresentationItems(){
        return this.props.slidePresentations.map((item, index) => {
            return(
                <Col sm={12} md={6} lg={4} className="mb-5" key={index}>
                    <NewsItem 
                        image={`${BASE_URL}${item.image}`}
                        text={item.title}
                        link={`${BASE_URL}${item.pdf}`}
                        alt={item.title}
                    />
                </Col>
            )
        })
    }

    render(){

        if(this.props.data === null){
            return <PageNotFound />
        }

        if(!this.props.data || !this.props.slidePresentations) { return <Loader /> }

        const {
            title,
            body,
            image,
            video,
            description,
            logo,
            website
        } = this.props.data

        const ldJson = {
            "@context": "https://schema.org",
            "@type": "Winery",
            "name": title,
            "image": BASE_URL + image,
            "@id": BASE_URL + logo,
            "url": website ? website : window.location.href,
            "telephone": "",
            "menu": "",
            "servesCuisine": "",
            "acceptsReservations": "false",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "",
              "addressLocality": "",
              "postalCode": "",
              "addressCountry": ""
            }  
        }

        return(
            <React.Fragment>

                <Helmet>
                    <title>Indigo Wine Group | {this.props.data.title}</title>
                    <meta name="description" content={description ? description : 'We are dedicated to developing and maintaining strong partnerships with superior wine brands of exceptional value.'} />
                    <meta name="robots" content="index, follow" />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                </Helmet>

                {
                    image && (
                        <figure>
                            <img src={`${BASE_URL}${image}`} 
                                alt={`${title} Wine Portfolio`} 
                                title={`${title} Wine Portfolio`}
                            />
                        </figure>
                    )
                }

                {
                    title && (
                        <h1 className="in_heading-3">{title}</h1>
                    )
                }

                {
                    body && <div className="in_innerHTML mb-5" dangerouslySetInnerHTML={{ __html: body }}></div>
                }              

                {
                    video && (
                        <YoutubeItem 
                            title={title}
                            link={getLastSegmentOfURL(video)}
                        />
                    )
                }

                <HR />

                <h2 className="in_heading-3">Full Range</h2>
                
                { this.props.fullRange && this.renderFullRange() }  

                

                {
                    this.props.slidePresentations && this.props.slidePresentations.length > 0 && ( 
                        <section className="in_slidepresentation-list-section">
                            <h2 className="in_heading-3">Slide Presentation</h2>
                            <Row>
                                {this.renderSliderPresentationItems()}
                            </Row>
                        </section> 
                    )
                }               
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.brandOverviewState[ownProps.brand],
        fullRange: state.brandFullRangeState[ownProps.brand],
        slidePresentations: state.slidePresentationsState[ownProps.brand]
    }
}

export default connect(mapStateToProps, {
    fetchBrandFullRange,
    fetchBrandSlidePresentations
})(BrandOverviewTab)