import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import AccoladesListSection from '../components/sections/AccoladesListSection'
import NeedHelpSection from '../components/sections/NeedHelpSection'

class Accolades extends React.Component{
    renderPageContent(){
        return(
            <Nav>
                <HeadingSection 
                    headline="Award-Winning Wines"
                    text="Our international portfolio includes award-winning wines of exceptional value from around the world. Imported and distributed throughout the USA by Indigo Wine Group."
                />
                <AccoladesListSection />
                <NeedHelpSection />
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Indigo Wine Group | Accolades</title>
                    <meta name="description" content="Our international portfolio includes award-winning wines of exceptional value from around the world. Imported and distributed throughout the USA by Indigo Wine Group." />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

export default Accolades