import React from 'react'
import {
    Router,
    Switch,
    Route
} from 'react-router-dom'
import history from './history'

//pages
import Home from './pages/Home'
import About from './pages/About'
import Portfolio from './pages/Portfolio'
import Accolades from './pages/Accolades'
import News from './pages/News'
import SocialFeed from './pages/SocialFeed'
import Contact from './pages/Contact'
import WineIndustry from './pages/WineIndustry'
import BrandPortfolio from './pages/BrandPortfolio'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Employee from './pages/Employee'
import PageNotFound from './pages/PageNotFound'
import Thankyou from './pages/Thankyou'
import WhereToBuy from './pages/WhereToBuy'

import './assets/scss/App.scss'
import ScrollToTop from './components/ScrollToTop'
import AgeGate from './components/AgeGate'
import {connect} from 'react-redux'

class App extends React.Component {
    render(){
        return(
            <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
                <ScrollToTop />
                {
                    !this.props.verified && <AgeGate />
                }                
                <Switch>                
                    <Route exact path="/" component={Home} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/portfolio" component={Portfolio} />
                    <Route exact path="/portfolio/:brandSearchName" component={BrandPortfolio} />
                    <Route exact path="/portfolio/:brandSearchName/:searchName" component={BrandPortfolio} />
                    <Route exact path="/portfolio/:brandSearchName/:searchName/:productSearchName" component={BrandPortfolio} />
                    <Route exact path="/accolades" component={Accolades} />
                    <Route exact path="/news" component={News} />
                    <Route exact path="/wine-industry" component={WineIndustry} />
                    {/*<Route exact path="/social-feed" component={SocialFeed} />*/}
                    <Route exact path="/contact" component={Contact} />
                    <Route exact path="/thankyou" component={Thankyou} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                    <Route exact path="/employee/" component={Employee} />
                    <Route exact path="/employee/:searchName" component={Employee} />
                    <Route exact path="/where-to-buy" component={WhereToBuy} />
                    <Route path="/wineries" component={Portfolio} />
                    <Route component={PageNotFound} />
                    
                </Switch>
            </Router>
        )
    }
}

const mapStateToProps = state => {
    return{
        verified: state.veryfyAgeState
    }
}

export default connect(mapStateToProps)(App)