import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ContactForm from '../components/forms/ContactForm'
import TeamSection from '../components/sections/TeamSection'
import {connect} from 'react-redux'
import {
    contactFormEmail
} from '../actions'

class Contact extends React.Component{

    onSubmit = formValues => {
        this.props.contactFormEmail(formValues)
    }

    renderContactForm(){
        return(
            <Col sm={12} lg={7}>
                <h2 className="in_heading-3-1">Get in touch</h2>
                <ContactForm onSubmit={this.onSubmit} />
                <br /><br />
            </Col>
        )
    }

    renderPageContent(){
        return(
            <Nav>
                <section className="in_heading-section text-center">
                    <Container>
                        <h1 className="in_heading-1 text-center">Contact Us</h1>
                    </Container>
                </section>
                <section className="in_contact-section" id="in_thankyou">
                    <Container>
                        <Row>
                            {this.renderContactForm()}
                            <Col sm={12} lg={5}>
                                <p className="in_paragraph">We are here to help answer any questions you might have. We look forward to hearing from you.</p>
                                <h2>
                                    <a className="in_heading-3-2" href="tel:(941) 483-1816" title="Call us">(941) 483-1816</a>
                                </h2>
                                <div className="in_google-map">
                                    <iframe 
                                    title="Indigo Google Map Location"
                                    className="in_google-map-iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3552.470117896054!2d-82.43061978511311!3d27.07846888305645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c35a33b2ce3051%3A0x27f4f1fc08eda9a6!2s1501%20S%20Tamiami%20Trail%20%23501%2C%20South%20Venice%2C%20FL%2034239%2C%20USA!5e0!3m2!1sen!2sza!4v1597755500993!5m2!1sen!2sza" width="650" height="450" frameBorder="0" style={{border:'0'}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                                </div>                                    
                            </Col>
                        </Row>
                    </Container>
                </section>   
                <TeamSection />                 
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Indigo Wine Group | Contact Us</title>
                    <meta name="description" content="Contact us. We are here to help answer any questions you might have. International wines imported and distributed throughout the USA by Indigo Wine Group." />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

export default connect(null, {
    contactFormEmail
})(Contact)