import React from 'react'

class TeamItem extends React.Component {
    
    render(){
        const {image, jobtitle, fullname, email, alt} = this.props

        if(image){
            return(
                <div className="in_team-item text-center">
                    <figure>
                        <img src={image} alt={alt} />
                        <figcaption>
                            <h3 className="in_heading-7">{fullname}</h3>
                            <p className="in_paragraph-4">{jobtitle}</p>
                            <a href={`mailto:${email}?subject=Indigo Wine Group Enquiry`} className="in_paragraph-5" title={`E-mail ${fullname}`}>{email}</a>
                        </figcaption>
                    </figure>    
                </div>
            )
        }

        return(
            <div className="in_team-item text-center">
                <h3 className="in_heading-7">{fullname}</h3>
                <p className="in_paragraph-4">{jobtitle}</p>
                <a href={`mailto:${email}?subject=Indigo Wine Group Enquiry`} className="in_paragraph-5" title={`E-mail ${fullname}`}>{email}</a>    
            </div>
        )        
    }
}

export default TeamItem