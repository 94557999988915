import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ContactForm from '../forms/ContactForm'
import get_in_touch from '../../assets/img/get-in-touch.jpg'
import {connect} from 'react-redux'
import {
    contactFormEmail
} from '../../actions'

class GetInTouchSection extends React.Component {

    state = {
        sent: false
    }

    onSubmit = formValues => {
        console.log(formValues)
        this.props.contactFormEmail(formValues)
        this.setState({
            sent: true
        })
    }

    renderContent(){
        if(this.state.sent){

            return(
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={12} lg={8}>
                            <h2 className="in_heading-1 text-center">Thank you for your enquiry!</h2>
                            <p className="in_paragraph-2 text-center">
                            An Indigo Wine Group representative will be in touch as soon as possible.
                            </p>
                        </Col>
                    </Row>
                </Container>
            )
        }
        return(
            <Container>
                <Row className="justify-content-center">
                    <Col sm={12} lg={8}>
                        <h2 className="in_heading-1 text-center">Get In Touch</h2>
                        <p className="in_paragraph-2 text-center">
                        We are here to help answer any questions you might have. We look forward to hearing from you.
                        </p>
                    </Col>
                    <Col sm={12} lg={8}>
                        <ContactForm onSubmit={this.onSubmit} />
                    </Col>
                </Row>
            </Container>            
        )
    }

    render(){
        return(
            <section id="in_thankyou" className="in_getintouch-section" style={{
                backgroundImage:`url(${get_in_touch})`
            }}>
                {this.renderContent()}
            </section>
        )
    }
}

export default connect(null, {
    contactFormEmail
})(GetInTouchSection)