import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'

class Thankyou extends React.Component{

    renderPageContent(){
        return(
            <Nav>
                <HeadingSection 
                    headline="Thank You"
                    text="We will respond to your inquiry as quickly as possible and then get back to you."
                />      
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Indigo Wine Group | Thank You</title>
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

export default Thankyou