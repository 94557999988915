import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import distributionMap from '../../assets/img/distribution-map.svg'
import {ButtonLink} from '../buttons'

class IndigoWinesSection extends React.Component{
    render(){
        return(
            <section className="in_indigo-wines-section">
                <Container>
                    <Row>
                        <Col sm={12} lg={7} className="in_col">
                            <figure>
                                <img src={distributionMap} alt="Indigo Wine Group Distribution Map" title="Indigo Wine Group Distribution Map" />
                            </figure>
                            
                        </Col>
                        <Col sm={12} lg={5} className="in_col">
                            <h1 className="in_heading-4">INDIGO WINE GROUP</h1>
                            <h2 className="in_heading-3">We distribute a selection of the finest international wines throughout the USA.</h2>
                            <ButtonLink
                                text="Explore" 
                                link="/about"
                            />
                        </Col> 
                    </Row>
                </Container>
            </section>
        )
    }
}

export default IndigoWinesSection