import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
//persisting the state after page refresh by saving it into local storage
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'

//reducers
import brandsReducer from './brandsReducer'
import brandsCountriesReducer from './brandsCountriesReducer'
import brandOverviewReducer from './brandOverviewReducer'
import brandFullRangeReducer from './brandFullRangeReducer'
import productReducer from './productReducer'
import brandNewsAndAccoladesReducer from './brandNewsAndAccoladesReducer'
import brandRangesReducer from './brandRangesReducer'
import newsReducer from './newsReducer'
import productResourcesReducer from './productResourcesReducer'
import teamReducer from './teamReducer'
import brandGalleryReducer from './brandGalleryReducer'
import slidePresentationsReducer from './slidePresentationsReducer'
import productSlidePresentationsReducer from './productSlidePresentationsReducer'
import veryfyAgeReducer from './veryfyAgeReducer'
import employeeSigninReducer from './employeeSigninReducer'
import employeeResourcesReducer from './employeeResourcesReducer'
import contactFormReducer from './contactFormReducer'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['employeeSigninState', 'veryfyAgeState']
}

const rootReducer = combineReducers({
    brandsState: brandsReducer,
    brandsCountriesState: brandsCountriesReducer,
    brandOverviewState: brandOverviewReducer,
    brandFullRangeState: brandFullRangeReducer,
    productState: productReducer,
    brandNewsAndAccoladesState: brandNewsAndAccoladesReducer,
    brandRangesState: brandRangesReducer,
    newsState: newsReducer,
    productResourcestate: productResourcesReducer,
    teamState: teamReducer,
    brandGalleryState: brandGalleryReducer,
    slidePresentationsState: slidePresentationsReducer,
    productSlidePresentationsState: productSlidePresentationsReducer,
    veryfyAgeState: veryfyAgeReducer,
    employeeSigninState: employeeSigninReducer,
    employeeResourcesState: employeeResourcesReducer,
    contactFormState: contactFormReducer,
    form: formReducer,
})

export default persistReducer(persistConfig, rootReducer)