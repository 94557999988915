import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import NeedHelpSection from '../components/sections/NeedHelpSection'
import SocialFeedSection from '../components/sections/SocialFeedSection'

class SocialFeed extends React.Component{

    renderPageContent(){
        return(
            <Nav>
                <HeadingSection 
                    headline="Social Media News"
                    text="Stay up to date and connect with us on our social media feed."
                />
                <div className="in_social-media-news-wrapper">
                    <SocialFeedSection />
                </div>
                <NeedHelpSection />
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Indigo Wine Group | Social Media News</title>
                    <meta name="description" content="Social media news – stay up to date on all things Indigo on our live social feed. International wines imported and distributed throughout the USA by Indigo Wine Group." />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

export default SocialFeed