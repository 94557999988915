import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import {BASE_URL} from '../path'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// https://github.com/frontend-collective/react-image-lightbox

export default class ImageLightbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  renderGalleryItems(){
    return this.props.images.map((item, index) => {
      return (
        <Col xs={6} md={4} lg={3} className="mb-4" key={index}>
          <div onClick={() => this.setState({ isOpen: true, photoIndex: index })} className="in_image-item" title="Open Gallery Slideshow - Lightbox Mode" >
              <img src={BASE_URL + item.image_thumb} alt={item.alt ? item.alt : `Indigo Wines image ${index}`} />
          </div>
        </Col>
      )
    })
  }

  render() {
    const { photoIndex, isOpen } = this.state;

    const images = this.props.images

    return (
      <>
          <Row className="in_image-gallery-row">
            {this.renderGalleryItems()}
          </Row>
        

        {isOpen && (
          <Lightbox
            mainSrc={BASE_URL + images[photoIndex].image_full}
            nextSrc={BASE_URL + images[(photoIndex + 1) % images.length].image_full}
            prevSrc={BASE_URL + images[(photoIndex + images.length - 1) % images.length].image_full}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
            animationOnKeyInput
            imageTitle={images[photoIndex].alt ? images[photoIndex].alt : `Indigo Wines ${photoIndex}`}
            clickOutsideToClose
          />
        )}
      </>
    );
  }
}