import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';

class CarouselItem extends React.Component {
    
    render(){
        return(
            <div className="in_carousel-item">
                <Link  scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })} to={this.props.link} title={`Go To ${this.props.heading} Wine Portfolio`}>
                    <figure>
                        <img src={this.props.image} alt={this.props.heading} />
                        
                    </figure>
                    <h4 className="in_heading-3-1">{this.props.heading}</h4>                    
                </Link>
            </div>
        )
    }
}

export default CarouselItem