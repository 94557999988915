import React from 'react'
import Logo from './Logo'
import WidescreenNav from './WidescreenNav'
import WidescreenMenu from './WidescreenMenu'
import MobileMenu from './MobileMenu'
import { MenuButton } from './buttons'
import Footer from './Footer'

class Nav extends React.Component {

    state = {
        menu: false,
        scrolled: false
    }

    componentDidMount(){
        window.addEventListener('scroll', this.onScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll)
    }

    onScroll = () => {
        if(window.scrollY > 0){
            if(!this.state.scrolled){
                this.setState({
                    scrolled: true
                })
            }
            
        } else {
            if(this.state.scrolled){
                this.setState({
                    scrolled: false
                })
            }
        }
    }

    onOpenMenu = () => {
        this.setState({
            menu: !this.state.menu
        })
    }

    render(){
        return(
            <React.Fragment>
                <header className={`in_header ${this.state.scrolled ? 'in_transformed' : ''}`}>
                    <div className="in_item in_item-left">
                        <Logo />
                    </div>
                    <div className="in_item in_item-center">
                        <WidescreenNav />
                    </div>
                    <div className="in_item in_item-right">
                        <MenuButton 
                            menu={this.state.menu}
                            onClick={this.onOpenMenu}
                        />
                        <div className={`in_flyopen-menu ${this.state.menu ? 'open' : ''}`}>
                            <WidescreenMenu onClick={this.onOpenMenu} />
                            <MobileMenu onClick={this.onOpenMenu} />
                        </div>
                    </div>
                </header>
                <main>{this.props.children}</main>

                <Footer />

            </React.Fragment>
            
        )
    }
}

export default Nav