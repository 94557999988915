import React from 'react'
import {connect}  from 'react-redux'
import {
    fetchProductResources
} from '../../actions'
import Loader from '../Loader'
import {BASE_URL} from '../../path'
import Dropdown from 'react-bootstrap/Dropdown';


const ArrowDown = () => {
    return (
        <div className="in_arrow-down"></div>
    )
}

class ProductResourcesTabs extends React.Component {

    state = {
        activeList: null
    }

    componentDidMount(){
        this.props.fetchProductResources(this.props.product)
    }

    OnClick = item => {
        this.setState({
            activeList: item
        })
    }

    renderButtons(){
        return Object.keys(this.props.resources).map((item, index) => {
            return(
                <button className="in_dropdown-button" onClick={() => this.OnClick(`item_${index}`)} key={index}>{item} <ArrowDown /></button>
            )
        })
    }

    renderList(){
        return Object.values(this.props.resources).map((category, index) => {
            if(this.state.activeList === `item_${index}`){
                return(
                    <div className="in_item" key={index}>
                        <ul>
                            {this.renderItems(category)}
                        </ul>
                    </div>
                )
            }
            return <React.Fragment key={index}></React.Fragment> 
        })
    }

    renderItems(category){
        return category.map((item, index) => {
            return(
                <li key={index}>
                    <a href={`${BASE_URL}${item.pdf}`} download={item.title}>{item.title}</a>
                </li>
            )
        })
    }

    renderDropdownList(category){
        return this.props.resources[category].map((item, index) => {
            return <Dropdown.Item key={index} href={`${BASE_URL}${item.pdf}`} download={item.title} target="_blank" title="View PDF">{item.title}</Dropdown.Item>
        })
    }

    renderDropdowns = category => {
        if(this.props.resources[category]){            
            return(
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" className="in_dropdown-button">
                        {category}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {this.renderDropdownList(category)}
                    </Dropdown.Menu>
                </Dropdown>
            )
        }
    }

    render(){
        if(!this.props.resources){return <Loader />}
        return(
            
            <div className="in_product-resources-dropdowns">
                {this.renderDropdowns("High Resolution")}
                {this.renderDropdowns("Labels")}
                {this.renderDropdowns("Range Presenter")}                
                {this.renderDropdowns("Point of Sale")}
                {this.renderDropdowns("Tasting Notes")}
                {this.renderDropdowns("Social Media")}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        resources: state.productResourcestate[ownProps.product]
    }
}

export default connect(mapStateToProps, {
    fetchProductResources
})(ProductResourcesTabs)