import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NewsItem from '../items/NewsItem'
import {connect} from 'react-redux'
import {
    fetchNews
} from '../../actions'
import Loader from '../Loader'
import {BASE_URL} from '../../path'

class WineIndustryListSection extends React.Component{
    componentDidMount(){
        this.props.fetchNews('wine-industry')
    }

    renderList(){
        return this.props.news.map((item,index) => {
            return(
                <Col sm={12} md={6} lg={4} xl={3} className="mb-5" key={index}>
                    <NewsItem 
                        image={`${BASE_URL}${item.image}`}
                        text={item.title}
                        link={`${BASE_URL}${item.pdf}`}
                        alt={item.title}
                    />
                </Col>
            )
        })
    }

    render(){
        if(!this.props.news){ return <Loader /> }
        return(
            <section className="in_news-list-section">
                <Container>
                    <Row>
                        {this.renderList()}
                    </Row>
                </Container>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return{
        news: state.newsState['wine-industry']
    }
}

export default connect(mapStateToProps, {
    fetchNews
})(WineIndustryListSection)