import {
    FETCH_GALLERY_IMAGES
} from '../actions/types'

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_GALLERY_IMAGES:
            return {
                ...state,
                [action.searchName]: action.payload
            }
        default:
            return state
    }
}