import React from 'react'
import {Link} from 'react-router-dom'
import AnimateScale from '../AnimateScale'

class PrivateLabelsItems extends React.Component {
    
    render(){
        const {image, link, text, alt} = this.props
        return(
            <AnimateScale>
                <Link to={link} className="in_private-label text-center" download target="_blank" title="Download PDF">
                    <figure className="in_overflow-hidden">
                        <img src={image} alt={alt} />
                    </figure>
                    <h4 className="in_paragraph-6">{text}</h4>
                </Link>
            </AnimateScale>
        )
    }
}

export default PrivateLabelsItems