import React from "react";

class AnimateScale extends React.Component {
    render(){
        return(
            <div className="in_animate-scale">
                {this.props.children}
            </div>
        )
    }
}

export default AnimateScale