import React from 'react'
import {
    Link
} from 'react-router-dom'
import history from '../history'

export class Button extends React.Component {

    onClick(){
        history.push('/')
    }

    render(){
        return(
            <button className="in_button" onClick={this.props.onClick ? this.props.onClick : this.onClick}>{this.props.text ? this.props.text : "Explore"}</button>
        )
    }    
}

export class ButtonFillColor extends React.Component {

    onClick(){
        history.push('/')
    }

    render(){
        return(
            <button className="in_button-fill" onClick={this.props.onClick ? this.props.onClick : this.onClick}>{this.props.text ? this.props.text : "Explore"}</button>
        )
    }    
}

export class ButtonLink extends React.Component {
    render(){
        return(
            <Link className="in_button" to={this.props.link ? this.props.link : "/"} title={this.props.title ? this.props.title : ''}>{this.props.text ? this.props.text : "Explore"}</Link>
        )
    }    
}

export class MenuButton extends React.Component {
    render(){
        return(
            <button className={`in_menu-button ${this.props.menu ? 'open' : ''}`} onClick={this.props.onClick} title={this.props.menu ? 'Close menu' : 'Open Menu'}>
                <div className="in_inner">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </button>
        )
    }    
}