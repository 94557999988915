import React from 'react'
import ReactDOM from 'react-dom'
import Logo from './Logo'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import image from '../assets/img/age-gate@2x.jpg'
import {ButtonFillColor} from './buttons'

class AgeGatePortal extends React.Component {

    render(){
        return(
            ReactDOM.createPortal(
                <div className={`in_agegate ${this.props.show ? 'in_show' : ''}`}>
                    <Container fluid>
                        <Row>
                            <Col sm={12} md={6} xl={7} className="in_left">
                                <aside>
                                    <Logo />
                                    <h2 className="in_heading-3 in_heading">We distribute a selection of the finest international wines throughout the USA.</h2>
                                    <div className="in_verify-panel">
                                        <p className="in_heading-9">YOU MUST BE 21 YEARS OF AGE TO CONTINUE BROWSING THIS SITE</p>
                                        <div className="in_inner">
                                            <ButtonFillColor 
                                                text="YES, I’M 21 OR OLDER"
                                                onClick={this.props.onClick}
                                            />
                                        </div>
                                    </div>
                                </aside>                                    
                            </Col>
                            <Col sm={12} md={6} xl={5} className="in_right">
                                <div className="in_image" style={{
                                    backgroundImage: `url(${image})`
                                }}></div>
                            </Col>
                        </Row>
                    </Container>
                </div>,
                document.querySelector("#agegate")
            )
        )
    }
}

export default AgeGatePortal