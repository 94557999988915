import React from 'react'
import Container from 'react-bootstrap/Container';
import {ButtonLink} from '../buttons'

//images
import accoladesBanner from '../../assets/img/accolades-parallax-scroll.jpg'
import plusImage from '../../assets/img/90-Plus@2x.png'

class AccoladesSection extends React.Component{
    render(){
        return(
            <section className="in_accolades-section" style={{
                backgroundImage: `url(${accoladesBanner})`
            }}>
                <Container>
                    <div className="in_inner">
                        <figure>
                            <img src={plusImage} alt="accolades Plus" />
                        </figure>
                        <h2 className="in_heading-4">ACCOLADES</h2>
                        <h2 className="in_heading-1">Award-Winning Wines</h2>
                        <p className="in_paragraph">
                            Our international portfolio includes award-winning wines of exceptional value from around the world.
                        </p>
                        <ButtonLink 
                            text="Explore"
                            link="/accolades"
                        />
                    </div>  
                    <div className="in_overlay"></div>
                </Container>
            </section>
        )
    }
}

export default AccoladesSection