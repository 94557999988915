import React from 'react'
import Container from 'react-bootstrap/Container';

class YearsOfExperience25Section extends React.Component{
    render(){
        return(
            <Container className="in_25-years-of-experience-container">
                <div className="in_inner">
                    <h2 className="in_left in_col">
                        <span className="in_custom-heading">
                            250
                        </span>
                        <span className="in_custom-heading">
                            Years of Experience
                        </span>
                    </h2>
                    <div className="in_right in_col">
                        <blockquote>
                            <p className="in_quote-paragraph"><i>“In addition to the Venice Headquarters, Indigo employs Regional Sales Representatives based throughout the United States. Along with management, they represent a combined total of 250 plus years of experience in building wine brands in the United States. Indigo’s philosophy is to offer its suppliers and distribution partners the highest level of representation, service and support.”</i></p>
                        </blockquote>
                    </div>
                </div>
            </Container>
        )
    }
}

export default YearsOfExperience25Section