import React from 'react'
import Helmet from 'react-helmet'
import Nav from '../components/Nav'
import HeadingSection from '../components/sections/HeadingSection'
import WineIndustryListSection from '../components/sections/WineIndustryListSection'
import NeedHelpSection from '../components/sections/NeedHelpSection'
import WineRegionsSection from '../components/sections/WineRegionsSection'

class WineIndustry extends React.Component{

    renderPageContent(){
        return(
            <Nav>
                <HeadingSection 
                    headline="Indigo Wine Industry"
                    text="Discover and learn about the wine regions, by country, included in the Indigo Wine Group portfolio."
                />
                <WineIndustryListSection />
                <WineRegionsSection />
                <NeedHelpSection />
            </Nav>
        )
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Indigo Wine Group | Wine Industry</title>
                    <meta name="description" content="Discover and learn about the wine regions, South Africa, Argentina, Australia and New Zealand, in the Indigo Wine Group portfolio imported and distributed throughout the USA." />
                </Helmet>
                {this.renderPageContent()}
                
            </React.Fragment>
        )
    }
}

export default WineIndustry