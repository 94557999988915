import {
    EMPLOYEE_SIGNIN,
    EMPLOYEE_SIGNOUT
} from '../actions/types'

export default (state = {}, action) => {
    switch(action.type){
        case EMPLOYEE_SIGNIN:
            return action.payload
        case EMPLOYEE_SIGNOUT:
            return action.payload
        default:
            return state
    }
}