import React from 'react'
import {BASE_URL} from '../path'

import instagramIcon from '../assets/img/instagram-icon.svg'
import facebookIcon from '../assets/img/facebook-icon.svg'
import pinterestIcon from '../assets/img/pinterest-icon.svg'
import twitterIcon from '../assets/img/twitter-icon.svg'
import websiteIcon from '../assets/img/website-icon.svg'
import youtubeIcon from '../assets/img/youtube-icon.svg'

class BrandTopHeader extends React.Component {

    render(){
        if(!this.props.data) { return <></> }
        const {
            title,
            logo,
            facebook,
            instagram,
            youtube,
            pinterest,
            twitter,
            website
        } = this.props.data

        return(
            <div className={`in_top-header ${this.props.className ? this.props.className : ''}`}>
                <figure className="in_th-logo">
                    <img src={`${BASE_URL}${logo}`} alt={`${title}`} title={`${title}`} />
                </figure>
                <nav className="in_th-socialmedia-nav">
                    <ul>     
                        {
                            facebook && (
                                <li>
                                    <a href={facebook} target="_blank" rel="noopener noreferrer">
                                        <img src={facebookIcon} alt="facebook" title={`View ${title} facebook page`} />
                                    </a>
                                </li>
                            )
                        }  
                        {
                            instagram && (
                                <li>
                                    <a href={instagram} target="_blank" rel="noopener noreferrer">
                                        <img src={instagramIcon} alt="instagram" title={`View ${title} Instagram page`} />
                                    </a>
                                </li>
                            )
                        }
                        {
                            twitter && (
                                <li>
                                    <a href={twitter} target="_blank" rel="noopener noreferrer">
                                        <img src={twitterIcon} alt="twitter" title={`View ${title} twitter page`} />
                                    </a>
                                </li>
                            )
                        }
                        {
                            youtube && (
                                <li>
                                    <a href={youtube} target="_blank" rel="noopener noreferrer">
                                        <img src={youtubeIcon} alt="youtube" title={`View ${title} Youtube Channel`} />
                                    </a>
                                </li>
                            )
                        }
                        {
                            pinterest && (
                                <li>
                                    <a href={pinterest} target="_blank" rel="noopener noreferrer">
                                        <img src={pinterestIcon} alt="pinterest" title={`View ${title} Pinterest page`} />
                                    </a>
                                </li>
                            )
                        }
                        {
                            website && (
                                <li>
                                    <a href={website} target="_blank" rel="noopener noreferrer">
                                        <img src={websiteIcon} alt="website" title={`View ${title} website`} />
                                    </a>
                                </li>
                            )
                        }                        
                    </ul>
                </nav>
            </div>
        )
    }
}

export default BrandTopHeader