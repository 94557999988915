import React from 'react'
import Helmet from 'react-helmet'
import ImageLightbox from '../ImageLightbox'
import {connect} from 'react-redux'
import {
    fetchBrandGallery
} from '../../actions'
import Loader from '../Loader'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class BrandImageGalleryTab extends React.Component {

    componentDidMount(){
        this.props.fetchBrandGallery(this.props.brand)
    }

    renderImages(){
        if(!this.props.images.length > 0){
            return(
                <Row>
                    <Col sm={12}>
                        <p className="in_heading-6">No images available for this brand</p>
                    </Col>
                </Row>
            )
        }
        return <ImageLightbox images={this.props.images} />
    }

    render(){
        if(!this.props.images || !this.props.data){return <Loader />}
        return(
            <React.Fragment>
                <Helmet>
                    <title>Indigo Wine Group | {this.props.data.title} Image Gallery</title>
                    <meta name="description" content={this.props.data.meta_description_image_gallery ? this.props.data.meta_description_image_gallery : 'We are dedicated to developing and maintaining strong partnerships with superior wine brands of exceptional value.'} />
                </Helmet>
                <h1 className="in_heading-3">Image Gallery</h1>
                <section className="in_gallery-section mt-4">
                    {this.renderImages()}
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return{
        images: state.brandGalleryState[ownProps.brand]
    }
}

export default connect(mapStateToProps, {
    fetchBrandGallery
})(BrandImageGalleryTab)