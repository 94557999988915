import React from 'react'

export class HeadingPrimary extends React.Component {
    render(){
        return(
            <h1 className="in_heading-1 text-center">{this.props.text}</h1>
        )
    }    
}

export class HeadingSecondary extends React.Component {
    render(){
        return(
            <h3 className="in_heading-1 text-center">{this.props.text}</h3>
        )
    }    
}