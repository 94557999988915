import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class DoubleColumnItem extends React.Component {
    
    render(){
        const {order, id, image, title, subtitle, description, link} = this.props
        return(
            <section className="in_wine-regions-section" id={id}>
                <Container>
                    <Row>
                        <Col sm={12} lg={5} className={`in_col ${order ? 'in_order-2' : ''}`}>
                            <figure>
                                <img src={image} alt={`${subtitle} ${title}`} title={`${subtitle} ${title}`} />
                            </figure>
                        </Col>
                        
                        <Col sm={12} lg={7}>
                            <article>
                                <h2>
                                    <span className="in_heading-4">{subtitle}</span><br />
                                    <span className="in_heading-1">{title}</span>
                                </h2>
                                
                                <article>
                                    <p className="in_paragraph">{description}</p>
                                </article>

                                <a href={link} target="_blank" rel="noopener noreferrer" className="in_button mt-3" title={this.props.linkTitle ? this.props.linkTitle : 'Go to website'}>Explore</a>
                            </article>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default DoubleColumnItem